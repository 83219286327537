import { Button, Flex, TableCell, TableRow, TextField, VisuallyHidden } from '@aws-amplify/ui-react';
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form';
import { ManualItem } from 'models';
import { allAllowedCharsRegex } from 'utils/CharacterValidation';
import { ReactComponent as CloseIconSvg } from 'assets/icon-x.svg';
import { ReactComponent as TickIconSvg } from 'assets/icon-tick-small.svg';
import { useCallback, useRef, useState } from 'react';

interface ManualAddRowProps {
  control: Control<ManualItem, any>;
  errors: FieldErrorsImpl<{
    account: string;
    amount: string;
    accountUid: string;
  }>;
  handleCancelManualItem: () => void;
}

const invalidAmountLabel = 'Whole numbers only';
const amountErrorId = 'amount-error';
const nameErrorId = 'name-error';

export const ManualAddRow = (props: ManualAddRowProps) => {
  const { control, errors, handleCancelManualItem } = props;
  const [invalidInputAttemptError, setInvalidInputAttemptError] = useState<string | undefined>(undefined);
  const amountErrorFieldRef = useRef<HTMLInputElement | null>(null);
  const nameErrorFieldRef = useRef<HTMLInputElement | null>(null);

  const hasAmountError = !!invalidInputAttemptError || !!errors.amount;
  const hasNameError = !!errors.account;

  // ARIA: Set the error field id to equal aria-describedby
  const ariaValidator = useCallback(() => {
    const amountErrorField = amountErrorFieldRef?.current?.parentNode?.parentNode?.parentNode?.querySelector(
      '.amplify-text.amplify-field__error-message'
    );
    const nameErrorField = nameErrorFieldRef?.current?.parentNode?.parentNode?.parentNode?.querySelector(
      '.amplify-text.amplify-field__error-message'
    );

    if (hasAmountError && amountErrorField) {
      amountErrorField.id = amountErrorId;
      amountErrorFieldRef?.current?.focus();
    }
    if (hasNameError && nameErrorField) {
      nameErrorField.id = nameErrorId;
      nameErrorFieldRef?.current?.focus();
    }
  }, [hasAmountError, hasNameError]);

  return (
    <TableRow className="manual-input-row">
      <TableCell />
      <TableCell className="manual-add-row-cells">
        <label className="amplify-label" htmlFor="manual-add-input-name">
          Name
        </label>
        <Controller
          name="account"
          control={control}
          defaultValue=""
          rules={{
            required: 'Account name is required',
            maxLength: { value: 50, message: 'Maximum 50 characters' },
            pattern: {
              value: allAllowedCharsRegex,
              message: 'Only alphanumeric and standard special characters allowed',
            },
            validate: (v) => v.trim().length > 0 || 'Account name is required',
          }}
          render={({ field }) => (
            <TextField
              className="manual-add-input-field report-input-field"
              testId="manual-add-input-name"
              id="manual-add-input-name"
              label="Account Name"
              aria-describedby={hasNameError ? nameErrorId : undefined}
              labelHidden
              {...field}
              ref={(element) => {
                nameErrorFieldRef.current = element;
                return { ...field.ref };
              }}
              maxLength={50}
              aria-invalid={hasNameError}
              hasError={hasNameError}
              errorMessage={errors.account?.message}
              autoFocus
            />
          )}
        />
      </TableCell>
      <TableCell className="manual-add-input-source">Manual</TableCell>
      <TableCell>
        <label className="amplify-label" htmlFor="manual-add-input-amount">
          Value
        </label>
        <Controller
          name="amount"
          control={control}
          defaultValue=""
          rules={{
            required: 'Amount is required',
            pattern: { value: /^-?\$?\d+$/, message: invalidAmountLabel },
            validate: (v) => v.trim().length > 0 || 'Amount is required.',
          }}
          render={({ field }) => (
            <TextField
              className="manual-add-input-field report-input-field"
              testId="manual-add-input-amount"
              id="manual-add-input-amount"
              label="Amount"
              innerStartComponent="$"
              aria-describedby={hasAmountError ? amountErrorId : undefined}
              maxLength={12}
              labelHidden
              {...field}
              ref={(element) => {
                amountErrorFieldRef.current = element;
                return { ...field.ref };
              }}
              aria-invalid={hasAmountError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.currentTarget.value = e.currentTarget.value.replace(/[$,]/g, '');
                if (/[^$\d-]/.test(e.currentTarget.value) || e.currentTarget.value.slice(1).includes('-')) {
                  setInvalidInputAttemptError(invalidAmountLabel);
                  return;
                }
                setInvalidInputAttemptError(undefined);
                field.onChange(e);
              }}
              hasError={hasAmountError}
              errorMessage={invalidInputAttemptError || errors.amount?.message}
            />
          )}
        />
      </TableCell>
      <TableCell className="manual-add-action-cell">
        <Flex gap="8px">
          <Button
            className="manual-add-action-btn"
            testId="manual-add-save-btn"
            variation="primary"
            type="submit"
            onClick={() => ariaValidator()}
          >
            <VisuallyHidden>save</VisuallyHidden>
            <TickIconSvg className="icon" />
          </Button>
          <Button className="manual-add-action-btn" testId="manual-add-cancel-btn" onClick={handleCancelManualItem}>
            <VisuallyHidden>cancel</VisuallyHidden>
            <CloseIconSvg />
          </Button>
        </Flex>
      </TableCell>
    </TableRow>
  );
};
