/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Button, Flex, Heading, Image, Text } from '@aws-amplify/ui-react';
import { useOktaAuth } from '@okta/okta-react';
import { InlineError } from 'components/InlineError/InlineError';
import { useState } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';

import { recordRumCustomEvent } from 'services/awsRum';
import { useLogOut } from 'utils/useLogOut';
import CustomAmplifyTheme from 'assets/CustomAmplifyTheme';
import { getRumAttributes } from 'utils/getRumAttributes';
import { useSaveReport } from 'utils/useSaveReport';
import { useSetReportLock } from 'utils/useSetReportLock';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import ClockIcon from 'assets/icon-clock.svg';
import { useIdleTimer } from 'react-idle-timer';
import CountdownClock from './CountdownClock';

import './UserInactivity.scss';

export const TIMEOUT_PARAM = 'timeout';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(75, 79, 84, 0.17)',
    backdropFilter: ' blur(3px)',
    zIndex: '10000',
  },
};

export const UserInactivity = ({ timeouts }: { timeouts: [number, number] }) => {
  const { oktaAuth } = useOktaAuth();
  const [inlineError] = useState(false);
  const saveReport = useSaveReport(false);
  const isInReport = !!useLocation().pathname.includes('report');

  const [timerState, setTimerState] = useState<'Active' | 'Idle' | 'Prompted'>('Active');
  const [inactivityTimeout, promptTimout] = timeouts;

  const unlockReport = useSetReportLock('unlock');
  const lockReport = useSetReportLock('lock');

  const HandleSaveAndLogout = () => {
    if (isInReport) {
      saveReport(false);
    }
    savedReportDataItems({
      savedAnswers: [],
    });
    latestSavedReportDataItems({
      savedAnswers: [],
    });
    useLogOut(oktaAuth);
  };

  const HandleLogout = (state: string) => {
    unlockReport();
    recordRumCustomEvent(RumCustomEvent.timeout, getRumAttributes());
    useLogOut(oktaAuth, state);
  };

  const HandleCloseModal = () => {
    timer.activate();
    lockReport();
    recordRumCustomEvent(RumCustomEvent.timeoutModalDismiss, getRumAttributes());
  };

  const onPrompt = () => {
    setTimerState('Prompted');
    recordRumCustomEvent(RumCustomEvent.timeoutModalAppear, getRumAttributes());
  };

  const onIdle = () => {
    setTimerState('Idle');
  };

  const onActive = () => {
    setTimerState('Active');
  };

  const timer = useIdleTimer({
    timeout: inactivityTimeout,
    promptBeforeIdle: promptTimout,
    onPrompt,
    onIdle,
    onActive,
    crossTab: true,
    syncTimers: 200,
  });

  if (timerState === 'Idle') HandleLogout(TIMEOUT_PARAM);

  return (
    <Modal
      isOpen={timerState === 'Prompted'}
      style={customStyles}
      className="inactivity"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc
      testId="time-out"
      aria={{ labelledby: 'time-out-alert-button-group', modal: true }}
    >
      <CustomAmplifyTheme aria-modal="true" isModal>
        <div>
          <Image testId="clock-icon" className="clock-icon" src={ClockIcon} alt="Clock Icon" />
        </div>
        <Heading testId="inactivity-heading" level={1} className="heading">
          Are you still working?
        </Heading>
        <Text testId="inactivity-content" className="content">
          We've noticed you've been inactive and your session will expire soon for your security
        </Text>
        <div data-testid="clock" className="clock">
          <CountdownClock timer={timer} promptTimeout={promptTimout / 1000} />
        </div>
        <Flex id="time-out-alert-button-group" justifyContent="center">
          <Button
            testId="save-and-log-out-btn"
            data-fullwidth="true"
            variation="link"
            className="save-and-log-out-btn"
            onClick={() => HandleSaveAndLogout()}
          >
            {isInReport ? 'Save and log out' : 'Log out'}
          </Button>
          <Button data-fullwidth="true" testId="keep-working-btn" variation="primary" onClick={HandleCloseModal}>
            Keep working
          </Button>
        </Flex>
        {inlineError && <InlineError styles="time-out-alert-inline-error" />}
      </CustomAmplifyTheme>
    </Modal>
  );
};
