/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Text, Flex } from '@aws-amplify/ui-react';
import Select from 'react-select';

interface Props {
  title: string;
  value: any;
  handleChange: (e: { label: string; value: string }) => any;
  options: {
    label: string;
    value: string | number;
  }[];
  minWidth?: string;
}

const InsightsSelect: React.FC<Props> = ({ title, value, handleChange, options, minWidth = '150px' }) => (
  <Flex direction="column" gap="8px" testId={`select-insights-input-${title}`} minWidth={minWidth}>
    <Text className="select-business-label" data-testid="select-business-label">
      {title}
    </Text>
    <Select
      value={value}
      inputId="select-industry-input"
      options={options}
      maxMenuHeight={215}
      onChange={handleChange}
    />
  </Flex>
);

export default InsightsSelect;
