import { makeVar } from '@apollo/client';

/**
 * Indicators (as reactive variables) for in-progress operations.
 */

export const syncingDspItems = makeVar(false);

export const isSubmittingReport = makeVar(false);

export const savingLocalAnswersToServer = makeVar(false);

export const dashboardReportTabState = makeVar<'current' | 'past'>('current');

export const syncingDspBusinessNamesState = makeVar(false);

export const savingLocalAnswersStatus = makeVar<'idle' | 'saving' | 'saved' | 'error'>('idle');
