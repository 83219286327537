/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import dayjs from 'dayjs';

type ABSDataSet = {
  action: string;
  series: object;
};

export type SeriesValue = {
  id: string;
  keyPosition: number;
  values: {
    name: string;
    id: string;
    end?: string;
  }[];
};

export type ABSInsights = {
  dataSets: Array<ABSDataSet>;
  header: object;
  structure: {
    dimensions: {
      series: Array<SeriesValue>;
      observation: Array<SeriesValue>;
    };
  };
};

export type SelectOption = {
  label: string;
  value: string | number;
};

export interface Observations {
  observations: object;
}

export const defaultIndustry = {
  value: 'R',
  label: 'Arts and Recreation Services',
};

export const getBusinessInsights = async (): Promise<ABSInsights | string> => {
  let data = '';
  const fiveYearsAgo = dayjs().year() - 5;
  try {
    const response = await fetch(
      `https://api.data.abs.gov.au/data/QBIS/M2+M6+M8.CUR..TOT.10.AUS.Q?startPeriod=${fiveYearsAgo}`, // start period dynamic please
      { headers: { Accept: 'application/json' } }
    );
    if (!response.ok) {
      throw new Error(`HTTP error: Status ${response.status}`);
    }
    data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
  }
  return '';
};

export function getInsightsValue(key: string, insightData: Map<string, Observations>) {
  try {
    if (insightData) {
      const obs = Object.values(insightData.get(key)!.observations);
      return obs[0].length === 1 ? obs.flat() : [];
    }
  } catch (e) {
    console.error(e);
  }
  return [];
}

export const getSelectedIndustryIndex = (selectedIndustry: SelectOption, industries: SeriesValue) => {
  if (selectedIndustry && industries?.values) {
    return String(industries.values.findIndex((i) => i.id === selectedIndustry.value));
  }
  return '0';
};

export const getKeyDimentionalSeriesKey = (pos: number, value: string, key = '0:0:0:0:0:0:0') => {
  const newPos = pos === 0 ? 0 : pos * 2;
  return key.substring(0, newPos) + value + key.substring(newPos + 1);
};
