import {
  SavedActionListNumeric,
  SavedActionListNumericItem,
  SavedActionNumber,
  SavedActionText,
  SavedReportDataItem,
  SavedReportDataItems,
} from 'models';
import { payloadLimitReportDataItems } from './payloadLimit';

/**
 * Look up a matching answer from a list of answers.
 *
 * @param referenceAnswer answer to find a match for, by dataItemId
 * @param savedAnswers answers to look for match in
 * @returns the answer from savedAnswers with matching dataItemId to referenceAnswer, or undefined if none match
 */
const getAssociatedSavedAnswer = (
  referenceAnswer: SavedReportDataItem,
  savedAnswers: SavedReportDataItems
): SavedReportDataItem | undefined =>
  savedAnswers.savedAnswers.find((answer) => answer.dataItemId === referenceAnswer.dataItemId);

const getUpdate = (
  localSavedAnswer: SavedReportDataItem,
  serverSavedAnswer: SavedReportDataItem
): SavedReportDataItem | undefined => {
  if ('savedNumber' in localSavedAnswer.savedReportDataItem) {
    const userValue =
      localSavedAnswer.savedReportDataItem.savedNumber !== undefined
        ? localSavedAnswer.savedReportDataItem.savedNumber.toString()
        : '';

    if (userValue === (serverSavedAnswer.savedReportDataItem as SavedActionNumber).savedNumber?.toString()) {
      return undefined;
    }

    const reportDataItem: SavedReportDataItem = {
      ...localSavedAnswer,
      overwrite: true,
    };

    if (userValue === '') {
      reportDataItem.delete = true;
      reportDataItem.overwrite = false;
    }

    return reportDataItem;
  }

  if ('savedText' in localSavedAnswer.savedReportDataItem) {
    if (
      localSavedAnswer.savedReportDataItem.savedText ===
      (serverSavedAnswer.savedReportDataItem as SavedActionText).savedText
    ) {
      return undefined;
    }

    const reportDataItem: SavedReportDataItem = {
      ...localSavedAnswer,
      overwrite: true,
    };

    if (localSavedAnswer.savedReportDataItem.savedText === '') {
      reportDataItem.delete = true;
      reportDataItem.overwrite = false;
      (reportDataItem.savedReportDataItem as SavedActionText).savedText = '';
    }

    return reportDataItem;
  }

  if ('selectedReportDataItems' in localSavedAnswer.savedReportDataItem) {
    const updatedSavedActionListNumericItems: SavedActionListNumericItem[] = [];

    localSavedAnswer.savedReportDataItem.selectedReportDataItems.forEach((localReportDataItem) => {
      const associatedServerReportDataItem = (
        serverSavedAnswer.savedReportDataItem as SavedActionListNumeric
      ).selectedReportDataItems.find(
        (serverReportDataItem) => serverReportDataItem.responseUid === localReportDataItem.responseUid
      );

      // No server value yet
      const previouslySaved = associatedServerReportDataItem !== undefined;

      // Server deleted, local updated
      const updateAfterDelete = previouslySaved && associatedServerReportDataItem.delete && !localReportDataItem.delete;

      if (!previouslySaved || updateAfterDelete) {
        updatedSavedActionListNumericItems.push(localReportDataItem);

        // has been saved and not in deleted state, did value/name/code change?
      } else if (
        associatedServerReportDataItem.userValue !== undefined &&
        associatedServerReportDataItem.userValue.length > 0 &&
        associatedServerReportDataItem.userValue !== localReportDataItem.userValue
      ) {
        localReportDataItem.overwrite = localReportDataItem.userValue !== '';
        updatedSavedActionListNumericItems.push(localReportDataItem);
      } else if (
        associatedServerReportDataItem.accountName !== undefined &&
        associatedServerReportDataItem.accountName.length > 0 &&
        associatedServerReportDataItem.accountName !== localReportDataItem.accountName
      ) {
        localReportDataItem.overwrite = true;
        updatedSavedActionListNumericItems.push(localReportDataItem);
      } else if (
        associatedServerReportDataItem.dspValue !== undefined &&
        associatedServerReportDataItem.dspValue.length > 0 &&
        associatedServerReportDataItem.dspValue !== localReportDataItem.dspValue
      ) {
        localReportDataItem.overwrite = true;
        updatedSavedActionListNumericItems.push(localReportDataItem);
      } else if (
        !!associatedServerReportDataItem.code &&
        associatedServerReportDataItem.code !== localReportDataItem.code
      ) {
        localReportDataItem.overwrite = true;
        updatedSavedActionListNumericItems.push(localReportDataItem);
      }
    });

    // have any items on the server been removed from local?
    (serverSavedAnswer.savedReportDataItem as SavedActionListNumeric).selectedReportDataItems.forEach(
      (serverReportDataItem) => {
        const associatedLocalReportDataItem = (
          localSavedAnswer.savedReportDataItem as SavedActionListNumeric
        ).selectedReportDataItems.find(
          (localReportDataItem) => localReportDataItem.responseUid === serverReportDataItem.responseUid
        );

        if (associatedLocalReportDataItem === undefined) {
          serverReportDataItem.overwrite = false;
          serverReportDataItem.delete = true;
          updatedSavedActionListNumericItems.push(serverReportDataItem);
        }
      }
    );

    // item is only updated if any of its items are updated
    if (!updatedSavedActionListNumericItems.length) return undefined;

    const updatedSavedActionListNumeric: SavedActionListNumeric = {
      dataItemId: localSavedAnswer.dataItemId,
      selectedReportDataItems: updatedSavedActionListNumericItems,
    };

    const updatedSavedAction: SavedReportDataItem = {
      ...localSavedAnswer,
      savedReportDataItem: updatedSavedActionListNumeric,
    };

    return updatedSavedAction;
  }

  return undefined;
};

export const getUpdatedReportDataItems = (
  localSavedData: SavedReportDataItems,
  serverSavedData: SavedReportDataItems
): SavedReportDataItems => {
  const reportChanges: SavedReportDataItems = {
    savedAnswers: [],
  };
  localSavedData.savedAnswers.forEach((reportDataItem) => {
    const associatedServerSavedItem = getAssociatedSavedAnswer(reportDataItem, serverSavedData);
    if (associatedServerSavedItem !== undefined) {
      const updatedChange = getUpdate(reportDataItem, associatedServerSavedItem);

      if (updatedChange !== undefined) {
        reportChanges.savedAnswers.push(updatedChange);
      }
    } else {
      reportDataItem.overwrite = false;
      reportChanges.savedAnswers.push(reportDataItem);
    }
  });

  return reportChanges;
};

/**
 * Clamp the size of updates to within the configured size.
 *
 * Note: limit is intentionally passed in to facilitate testing.
 *
 * @param updatedSavedValues all updated values that need to be saved
 * @param limit maximum number of items that can be included in a single save
 * @returns the passed in updatedSavedValues, or a new object with a subset of values that is within the limit
 */
export function limitUpdateReportDataItems(updatedSavedValues: SavedReportDataItems, limit: number) {
  const totalItems = updatedSavedValues.savedAnswers
    .map((dataItem) => {
      if ('selectedReportDataItems' in dataItem.savedReportDataItem) {
        // Updates are only sent for the selected data items, so do not count the item itself
        return dataItem.savedReportDataItem.selectedReportDataItems.length;
      }
      return 1;
    })
    .reduce((a, b) => a + b, 0);

  if (totalItems <= limit) {
    // No limiting required, give back original
    return updatedSavedValues;
  }

  let tally = 0;
  const updatesWithinLimit: SavedReportDataItem[] = [];
  const limitedUpdatedSavedValues: SavedReportDataItems = { savedAnswers: updatesWithinLimit };

  for (const answer of updatedSavedValues.savedAnswers) {
    if ('selectedReportDataItems' in answer.savedReportDataItem) {
      // LISTSUM: count based on mapped items
      const selectedItems = answer.savedReportDataItem.selectedReportDataItems;

      if (tally + selectedItems.length <= limit) {
        // all can fit, add item unchanged and continue
        tally += selectedItems.length;
        updatesWithinLimit.push(answer);
      } else {
        // cannot fit all, include what can fit
        const availableSpace = limit - tally;
        const limitedSelectedItems = selectedItems.slice(0, availableSpace);
        const limitedAnswer = {
          ...answer,
          savedReportDataItem: {
            ...answer.savedReportDataItem,
            selectedReportDataItems: limitedSelectedItems,
          },
        };

        tally += limitedSelectedItems.length;
        updatesWithinLimit.push(limitedAnswer);
      }
    } else {
      // simple items, or list item with no changes to list: count as single items
      tally += 1;
      updatesWithinLimit.push(answer);
    }

    if (tally === limit) {
      return limitedUpdatedSavedValues;
    }
  }

  // should not be reached based on ititial count, but include as safety net
  return limitedUpdatedSavedValues;
}

/**
 * Get the merged result of saving a subset of local changes to the server.
 *
 * This is needed for partial saves so that the difference between local and server
 * will be accurate when preparing the next save.
 *
 * Only use this if the update is partial. Full updates should just update server
 * value to the local value at the time save was triggered.
 *
 * @param localSavedData
 * @param serverSavedData
 * @param updates
 * @returns
 */
export function getMergedReportDataItems(
  localSavedData: SavedReportDataItems,
  serverSavedData: SavedReportDataItems,
  updates: SavedReportDataItems
): SavedReportDataItems {
  const { savedAnswers: serverAnswers } = serverSavedData;
  const { savedAnswers: updateAnswers } = updates;

  // Current state on server as the starting point
  const answersAfterSave: SavedReportDataItem[] = [...serverAnswers];

  for (const answerUpdate of updateAnswers) {
    const serverAnswer = getAssociatedSavedAnswer(answerUpdate, serverSavedData);
    const localAnswer = getAssociatedSavedAnswer(answerUpdate, localSavedData);

    if (localAnswer) {
      // must be add or update
      const answerIndex = answersAfterSave.findIndex((answer) => answer.dataItemId === answerUpdate.dataItemId);
      if (answerIndex < 0) {
        // add
        answersAfterSave.push(getMergedAnswerUpdate(undefined, answerUpdate, localAnswer));
      } else {
        // update
        answersAfterSave[answerIndex] = getMergedAnswerUpdate(answersAfterSave[answerIndex], answerUpdate, localAnswer);
      }
    } else if (serverAnswer) {
      // delete
      const answerIndex = answersAfterSave.findIndex((answer) => answer.dataItemId === answerUpdate.dataItemId);
      if (answerIndex >= 0) {
        // delete at the index (in-place)
        answersAfterSave.splice(answerIndex, 1);
      }
    }
  }

  return { savedAnswers: answersAfterSave };
}

type SavedActionTypes = SavedReportDataItem['savedReportDataItem'];
function isSavedActionListNumeric(
  savedReportDataItem: SavedActionTypes
): savedReportDataItem is SavedActionListNumeric {
  return 'selectedReportDataItems' in savedReportDataItem;
}

/**
 * Get the new value for a data item server state after a save, based on current states and update
 *
 * @param serverAnswer currente server answer if present
 * @param answerUpdate update operation generated from getUpdatedReportDataItems
 * @param localAnswer current local answer
 * @returns localAnswer if item is a simple type or a list item where the update includes all items,
 *   otherwise a clone of localAnswer with the expected items after the update is applied
 */
export function getMergedAnswerUpdate(
  serverAnswer: SavedReportDataItem | undefined,
  answerUpdate: SavedReportDataItem,
  localAnswer: SavedReportDataItem
) {
  // single-value types always use the local value if they are in the update
  if (!isSavedActionListNumeric(answerUpdate.savedReportDataItem)) {
    return localAnswer;
  }

  const dataItemUpdate = answerUpdate.savedReportDataItem;
  const serverDataItem = serverAnswer?.savedReportDataItem as SavedActionListNumeric | undefined;
  const localDataItem = localAnswer.savedReportDataItem as SavedActionListNumeric;

  const selectedItemUpdates = dataItemUpdate.selectedReportDataItems;
  // Default empty for new items as they have no server value
  const serverSelectedItems = serverDataItem?.selectedReportDataItems ?? [];
  const localSelectedItems = localDataItem.selectedReportDataItems;

  const serverUids = serverSelectedItems.map((item) => item.responseUid);
  const localUids = localSelectedItems.map((item) => item.responseUid);

  const deleteOperationUids = selectedItemUpdates.filter((item) => item.delete).map((item) => item.responseUid);
  const deletedUids = serverUids.filter((uid) => !localUids.includes(uid));
  const allDeletedAccountedFor = deletedUids.every((uid) => deleteOperationUids.includes(uid));

  const addOperationUids = selectedItemUpdates
    .filter((item) => !item.delete && !item.overwrite)
    .map((item) => item.responseUid);
  const addedUids = localUids.filter((uid) => !serverUids.includes(uid));
  const allAddedAccountedFor = addedUids.every((uid) => addOperationUids.includes(uid));

  const overwriteOperationUids = selectedItemUpdates.filter((item) => item.overwrite).map((item) => item.responseUid);
  const updatedOrUnchangedUids = serverUids.filter((uid) => localUids.includes(uid));

  // items present in both that have no update operation
  const maybeUpdatedAndNoOverwriteOperationUids = updatedOrUnchangedUids.filter(
    (uid) => !overwriteOperationUids.includes(uid)
  );
  const allMaybeUpdatedAreIdentical = maybeUpdatedAndNoOverwriteOperationUids.every((uid) => {
    const local = localSelectedItems.find((item) => item.responseUid === uid);
    const server = serverSelectedItems.find((item) => item.responseUid === uid);

    const identical =
      server?.accountName === local?.accountName &&
      server?.code === local?.code &&
      server?.dspValue === local?.dspValue &&
      server?.userValue === local?.userValue;

    return identical;
  });

  if (allDeletedAccountedFor && allAddedAccountedFor && allMaybeUpdatedAreIdentical) {
    // includes all updates, so we can use local value
    return localAnswer;
  }

  // Selected items after save are server items with updates applied
  const selectedReportDataItems = [...serverSelectedItems]
    .map((item) =>
      overwriteOperationUids.includes(item.responseUid)
        ? localSelectedItems.find((i) => i.responseUid === item.responseUid)!
        : item
    )
    .filter((item) => !deleteOperationUids.includes(item.responseUid))
    .concat(localSelectedItems.filter((item) => addOperationUids.includes(item.responseUid)));

  return {
    ...localAnswer,
    savedReportDataItem: {
      ...localAnswer.savedReportDataItem,
      selectedReportDataItems,
    },
  };
}

export function getUpdatedSavedValuesAndServerState(
  savedValues: SavedReportDataItems,
  serverSavedValues: SavedReportDataItems
) {
  const updatedSavedValues = getUpdatedReportDataItems(savedValues, serverSavedValues);
  // limit saved values to prevent failure from excessive payload size
  const limitedUpdatedSavedValues = limitUpdateReportDataItems(updatedSavedValues, payloadLimitReportDataItems);
  const savedAnswersForContext =
    limitedUpdatedSavedValues === updatedSavedValues
      ? savedValues
      : getMergedReportDataItems(savedValues, serverSavedValues, limitedUpdatedSavedValues);

  return [limitedUpdatedSavedValues, savedAnswersForContext] as const;
}
