/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, VisuallyHidden } from '@aws-amplify/ui-react';

import { resetLastSavedTimer } from 'apollo/states/SaveTimer';
import { savingLocalAnswersStatus } from 'apollo/states/operationsInProgress';
import { InlineError } from 'components/InlineError/InlineError';
import { getSaveReportOptions } from 'utils/getSaveReportOptions';
import { isMac } from 'utils/detectOS';
import useSaveReportWithResponse from 'utils/useSaveReportWithResponse';

import './ReportButtonGroup.css';

interface ButtonProps {
  link: string;
  text: string;
  useSave?: boolean;
  Callback?: () => void;
  isLoading?: boolean;
  loadingText?: string;
}

interface ReportButtonGroupProps {
  nextButton: ButtonProps;
  backButton?: ButtonProps;
}

const ReportButtonGroup = ({ nextButton, backButton }: ReportButtonGroupProps) => {
  const navigate = useNavigate();
  const [inlineError, setInlineError] = useState(false);
  const [buttonLoader, setButtonLoader] = useState('');
  const [navigateLink, setNavigateLink] = useState('');
  // For screen reader loading announcement, until button component handles it properly
  const [announceLoading, setAnnounceLoading] = useState(false);

  const [callSaveReport, saveReportResponse] = useSaveReportWithResponse();

  const handleOnClick = (clickedButton: string, link: string, useSave?: boolean) => {
    if (useSave) {
      if (clickedButton === 'back-button') {
        setButtonLoader('back');
      } else if (clickedButton === 'next-button') {
        setButtonLoader('next');
      }
      setInlineError(false);
      setNavigateLink(link);
      // we want to lock the report as we are making changes.
      const saveReportOptions = getSaveReportOptions(true);
      if (saveReportOptions.variables.reportDataObject.reportDataItems?.length) {
        // args and context need to us limiting
        callSaveReport(saveReportOptions);
        savingLocalAnswersStatus('saving');
      } else {
        if (clickedButton === 'next-button') resetLastSavedTimer();
        navigate(link);
      }
    } else {
      navigate(link);
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (saveReportResponse.error || (saveReportResponse.data && !saveReportResponse.data?.saveReport?.success)) {
      setInlineError(true);
      savingLocalAnswersStatus('error');
    }
    if (saveReportResponse.data && saveReportResponse.data?.saveReport?.success) navigate(navigateLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveReportResponse.error, saveReportResponse.data]);

  const isBackLoading = backButton
    ? backButton.isLoading || (saveReportResponse.loading && buttonLoader === 'back')
    : false;
  const isNextLoading = nextButton.isLoading || (saveReportResponse.loading && buttonLoader === 'next');

  const isLoading = isBackLoading || isNextLoading;
  useEffect(() => {
    // Add add small delay to announcing loading state, as recommended in accessibility report
    const timeoutHandle = setTimeout(() => setAnnounceLoading(isLoading), 200);
    return () => clearTimeout(timeoutHandle);
  }, [isLoading]);

  const loadingText =
    (isNextLoading && (nextButton.loadingText || 'Loading...')) ||
    (isBackLoading && (backButton?.loadingText || 'Loading...'));

  return (
    <Flex direction="column" alignItems="flex-end">
      <Flex className="report-button-group-wrapper">
        {backButton && (
          <Button
            className="report-button-group-button"
            testId="report-button-back"
            onClick={backButton.Callback ?? (() => handleOnClick('back-button', backButton.link, backButton.useSave))}
            isLoading={isBackLoading}
            loadingText={backButton.loadingText || 'Loading...'}
          >
            {backButton.text}
          </Button>
        )}
        <Button
          variation="primary"
          className="report-button-group-button"
          testId="report-button-next"
          onClick={nextButton.Callback ?? (() => handleOnClick('next-button', nextButton.link, nextButton.useSave))}
          isLoading={isNextLoading}
          loadingText={nextButton.loadingText || 'Loading...'}
        >
          {nextButton.text}
        </Button>
      </Flex>
      {inlineError && <InlineError />}
      {!isMac && <VisuallyHidden role="status">{announceLoading && <span>{loadingText}</span>}</VisuallyHidden>}
    </Flex>
  );
};
export default ReportButtonGroup;
