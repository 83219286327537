/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ReactElement, useEffect, useMemo } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, NormalizedCacheObject, from } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';

import { cache } from 'apollo/Cache';
import { env } from 'lib/env';
import { getLocalOktaToken } from 'lib/authConfig';
import createASPApiLink from '../link/ASPApiLink';

const { REACT_APP_APPSYNC_API_ENDPOINT } = env;

interface ASPApolloClientProps {
  children: ReactElement;
}
const ASPApolloClient = (props: ASPApolloClientProps) => {
  const { children } = props;
  const { authState } = useOktaAuth();
  const token = authState?.idToken?.idToken;

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        // Getting token from local storage as a backup in case useOktaAuth hook is not ready
        // This can happen when redirecting back after Okta login page when we attempt to call getUserProfile
        authorization: authState?.idToken?.idToken ?? getLocalOktaToken() ?? '',
      },
    });
    return forward(operation);
  });

  const client = useMemo<ApolloClient<NormalizedCacheObject>>(
    () =>
      new ApolloClient({
        link: from([authLink, new HttpLink({ uri: REACT_APP_APPSYNC_API_ENDPOINT })]),
        cache,
        connectToDevTools: env.REACT_APP_ENV === 'dev',
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!client) return;

    const addLink = async () => {
      const link = await createASPApiLink(token);
      if (link) client.setLink(link);
    };

    addLink();
  }, [client, token]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ASPApolloClient;
