import { useState } from 'react';
import { ErrorMessage } from 'models';
import { dspApiErrorCodes } from 'enums/ApiErrorMsg';
import ToastNotification from 'components/ToastNotification/ToastNotification';

const dspApiErrorToastHeader = 'Accounting software communication is down';
const dspApiErrorToastText =
  'Your accounting software is not currently sending us information. You will be unable to connect a business, launch a report or sync until this is working. Please try again later.';

/**
 * Checks error messages for DSP API type errors and renders message content in a ToastNotification.
 * @returns a tuple with:
 *     rendered toast component to insert in JSX,
 *     callback to show toast if given errors have an API error,
 *     callback to hide the toast
 */
const useDspApiErrorToast = () => {
  const [open, setOpen] = useState(false);

  const toast = (
    <ToastNotification
      success={false}
      title={dspApiErrorToastHeader}
      text={dspApiErrorToastText}
      open={open}
      onClose={() => setOpen(false)}
    />
  );

  /**
   * Shows DSP API error toast if any of errorMessages is a DSP API error
   * @param errorMessages to check for error type
   * @returns true if Toast was opened, otherwise false
   */
  const checkErrorCodes = (errorMessages: ErrorMessage[]) => {
    if (hasDspApiErrorCodes(errorMessages)) {
      setOpen(true);
      return true;
    }
    return false;
  };

  const clearToast = () => setOpen(false);

  return [toast, checkErrorCodes, clearToast] as const;
};

export default useDspApiErrorToast;

function hasDspApiErrorCodes(errorMessages: ErrorMessage[]) {
  return (Array.isArray(errorMessages) ? errorMessages : [errorMessages]).some((x) =>
    dspApiErrorCodes.includes(x.errorCode)
  );
}
