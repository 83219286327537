/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { useReactiveVar } from '@apollo/client';
import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { asSavedActionListNumeric, asSavedActionNumber, asSavedActionText } from './savedActionTypeUtil';

export const useTopicsWithMissingData = () => {
  const excludedTopics = ['ASPFeedback', 'Arbitrary', 'Profit or Loss'];

  const localSavedDataItems = useReactiveVar(savedReportDataItems);
  const reportTopics = useCurrentReportTopics();

  return reportTopics
    .filter(({ topic }) => {
      if (excludedTopics.includes(topic)) return;

      // check if every answer in the topic is blank
      return localSavedDataItems.savedAnswers
        .filter((answer) => answer.topic === topic)
        .every((answer) => {
          if (answer.delete) return true;

          const listsumItem = asSavedActionListNumeric(answer.savedReportDataItem);
          if (listsumItem)
            return listsumItem.selectedReportDataItems.every(
              ({ userValue, dspValue }) =>
                (userValue === '' || userValue === undefined) && (dspValue === '' || dspValue === undefined)
            );

          const numberItem = asSavedActionNumber(answer.savedReportDataItem);
          if (numberItem) return numberItem.savedNumber === undefined;

          const textItem = asSavedActionText(answer.savedReportDataItem);
          if (textItem) return textItem.savedText === '';

          return false;
        });
    })
    .map(({ topic }) => topic);
};
