import { Theme, ThemeProvider, defaultTheme } from '@aws-amplify/ui-react';

interface CustomAmplifyThemeProps {
  children: React.ReactNode;
  isModal?: boolean;
}

const theme: Theme = {
  name: 'abs-aps-theme',
  tokens: {
    fonts: { default: { static: { value: 'Open Sans' }, variable: { value: 'Open Sans' } } },
    components: {
      tabs: {
        item: {
          color: { value: '#000000' },
          _active: { color: { value: '#000000' } },
          _focus: { color: { value: '#000000' } },
        },
      },
      heading: {
        ...defaultTheme.tokens.components.heading,
        color: { value: '#000000' },
        3: {
          fontSize: { value: '28px' },
        },
      },
      alert: {
        // Default styles
        backgroundColor: { value: '{colors.teal.20}' },

        icon: {
          size: { value: '{fontSizes.xxxl}' },
        },

        // Variations
        info: {
          color: { value: '#000000' },
          backgroundColor: { value: '#D1DFF0' },
        },
      },
      switchfield: {
        ...defaultTheme.tokens.components.switchfield,
        thumb: {
          ...defaultTheme.tokens.components.switchfield.thumb,
          backgroundColor: { value: '#E9E9EA' },
          // borderColor: { value: '{colors.border.tertiary}' },
          transition: {
            duration: { value: '{time.long}' },
          },
        },

        track: {
          ...defaultTheme.tokens.components.switchfield.track,
          backgroundColor: { value: '#E9E9EA' },
          checked: {
            backgroundColor: { value: '#65C466' },
          },
          transition: {
            duration: { value: '{time.long}' },
          },
        },
      },
      button: {
        ...defaultTheme.tokens.components.button,
        borderColor: { value: '#326297' },
        borderRadius: { value: '8px' },
        primary: {
          backgroundColor: { value: '#326297' },
          color: { value: '#fff' },
          _hover: {
            backgroundColor: { value: '#153D73' },
          },
          _focus: {
            backgroundColor: { value: '#326297' },
          },
          _disabled: {
            backgroundColor: { value: '#71767d' },
            color: { value: '#FFF' },
          },
        },
        link: {
          borderWidth: { value: '1px' },
          borderColor: { value: '#326297' },
          color: { value: '#000000' },
          _hover: {
            backgroundColor: { value: '#A6D8FF' },
            borderColor: { value: '#326297' },
            color: { value: '#000000' },
          },
          _focus: {
            backgroundColor: { value: '#ffffff' },
            color: { value: '#000000' },
          },
        },
      },
    },
    colors: {
      brand: {
        primary: {
          20: { value: '#5a8abf' },
          40: { value: '#4676ab' },
          60: { value: '#326297' },
          80: { value: '#1e4e83' },
          90: { value: '#00325B' },
          100: { value: '#0a3a6f' },
        },
      },
    },
  },
};

const modalTheme: Theme = {
  ...theme,
  name: 'abs-aps-theme-modal',
};

const CustomAmplifyTheme = ({ children, isModal }: CustomAmplifyThemeProps) => (
  <ThemeProvider theme={isModal ? modalTheme : theme}>{children}</ThemeProvider>
);

export default CustomAmplifyTheme;
