/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Heading, Text, Grid, View, Flex, Link } from '@aws-amplify/ui-react';
import { externalLinks } from 'lib/externalLinks';
import Graph from './Graph';
import './InsightMeasureDetails.css';

interface Props {
  title: string;
  tagName: string;
  name: string;
  data: number[] | object[];
  catagories: string[];
  color?: string;
  whatIsIt: string;
  whyItMatters: string;
  displayCount: string | number;
  industry: string;
  loading: boolean;
  error?: boolean;
  link?: string;
  onRetry: () => void;
}

const InsightMeasureDetails: React.FC<Props> = ({
  title,
  tagName,
  name,
  data,
  catagories,
  color = '#2CAFFE',
  whatIsIt,
  whyItMatters,
  displayCount,
  industry,
  loading = true,
  error = false,
  link = externalLinks.absBusinessIndicators,
  onRetry,
}) => {
  const series = data.slice(data.length - Number(displayCount));
  const options: Highcharts.Options = {
    title: {
      text: name,
    },
    series: [
      {
        data: series, // values
        showInLegend: false,
        type: 'column',
        color,
        name: industry,
      },
    ],
    lang: {
      noData: `The ABS does not currently publish industry data on ${name.toLowerCase()} for ${industry}.`,
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030',
        textAlign: 'center !important',
        whiteSpace: 'normal !important',
      },
      useHTML: true,
    },
    chart: {
      type: 'column',
      borderRadius: 8,
    },
    tooltip: {
      valueSuffix: '%',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: catagories.slice(catagories.length - Number(displayCount)), // get from api resp
      reversed: false,
      title: {
        text: '',
      },
    },
    yAxis: {
      title: {
        text: 'Percentage change',
      },
      labels: {
        format: '{value}%',
      },
    },
    accessibility: {
      description: 'This chart displays',
    },
  };

  const getTag = () => {
    const lastValue = data[data.length - 1];
    if (data.length) {
      return `In the past quarter ${tagName} ${lastValue < 0 ? 'fell' : 'rose'} by ${lastValue}%.`;
    }
    return 'no data is currently available for this measure';
  };

  return (
    <Grid templateColumns="2fr 4fr">
      <Flex direction="column">
        <Heading level={3} style={{ margin: '0' }}>
          {' '}
          {title}
        </Heading>
        {loading ? <div className="skeleton" style={{ width: '85%', height: '20px' }} /> : <Text>{getTag()}</Text>}
        <Text>
          <b>What it is:</b> {whatIsIt}
        </Text>
        <Text>
          <b>Why it matters:</b> {whyItMatters}
        </Text>
        <Text>
          <Link isExternal href={link}>
            {' '}
            Explore {title.toLocaleLowerCase()} in ABS Data Explorer
          </Link>
        </Text>
      </Flex>
      <View>
        <Graph options={options} onRetry={onRetry} isLoading={loading} error={error} showRetry />
      </View>
    </Grid>
  );
};

export default InsightMeasureDetails;
