import { useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import { ChosenReport, SavedReportDataItems } from 'models';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { chosenReport } from 'apollo/states/ChosenReport';
import { mapLocalToGraphQL } from 'utils/mapLocalToGraphQL';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { getUpdatedSavedValuesAndServerState } from './getUpdatedReportDataItems';

/**
 * React hook that returns variables and context needed for a call to saveReport or submitReport.
 *
 * This will trigger component re-render when any of the variables update.
 * If you need the variables at a specific time (e.g. in an event handler)
 * you can use getSaveReportOptions to get the value once instead.
 *
 * @returns object to pass as 'variables' in a call to saveReport or submitReport
 */
export const useSaveReportOptions = () => {
  const savedValues = useReactiveVar<SavedReportDataItems>(savedReportDataItems);
  const serverSavedValues = useReactiveVar<SavedReportDataItems>(latestSavedReportDataItems);
  const chosenReportData = useReactiveVar<ChosenReport>(chosenReport);

  const saveReportVariables = useMemo(
    () => buildSaveReportOptions(savedValues, serverSavedValues, chosenReportData),
    [chosenReportData, savedValues, serverSavedValues]
  );

  return saveReportVariables;
};

/**
 * Plain function that returns variables and context needed for a call to saveReport or submitReport.
 *
 * If you need a component to re-render when these values change, use the hook version
 * useSaveReportOptions.
 *
 * @returns object to pass as 'variables' in a call to saveReport or submitReport
 */
export const getSaveReportOptions = (lockReport?: boolean) => {
  const savedValues = savedReportDataItems();
  const serverSavedValues = latestSavedReportDataItems();
  const chosenReportData = chosenReport();

  return buildSaveReportOptions(savedValues, serverSavedValues, chosenReportData, lockReport);
};

// Common logic for hook and non-hook ways to generate variables and context for saveReport/submitReport
const buildSaveReportOptions = (
  savedValues: SavedReportDataItems,
  serverSavedValues: SavedReportDataItems,
  chosenReportData: ChosenReport,
  lockReport?: boolean
) => {
  const [updatedSavedValues, savedAnswersForContext] = getUpdatedSavedValuesAndServerState(
    savedValues,
    serverSavedValues
  );

  const mappedSavedValues = mapLocalToGraphQL(updatedSavedValues, chosenReportData, false);

  // saved values are identical if all changes are being saved
  const partialSave = savedAnswersForContext !== savedValues;

  return {
    variables: {
      dspProvider: chosenReportData.dspProvider || '',
      organisationId: chosenReportData.organisationId || '',
      reportDataObject: mappedSavedValues,
      ...(lockReport != null ? { lockReport } : {}),
    },
    context: { savedAnswers: savedAnswersForContext.savedAnswers, partialSave },
  };
};
