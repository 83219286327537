/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import { TextField, Text, Flex } from '@aws-amplify/ui-react';
import { useReactiveVar } from '@apollo/client';

import { ReportListHeaderTitle } from 'components/ReportComponents/ReportList/ReportListHeader';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { currencyFormat } from 'utils/CurrencyFormat';
import { DataItem, SavedActionListNumeric, SavedReportDataItem } from 'models';

import './ReportListNoDsp.css';
import { formatValueString, stripCommas } from '../TopicQuestions/TopicQuestions';

interface ReportListNoDspProps {
  dataItem: DataItem;
  helpTexts: string[];
  minified?: boolean;
}

const ReportListNoDsp = ({ dataItem, helpTexts, minified }: ReportListNoDspProps) => {
  const savedValues = useReactiveVar(savedReportDataItems);
  const serverValues = useReactiveVar(latestSavedReportDataItems);

  const [hasError, setHasError] = useState(false);
  const [isNegative, setIsNegative] = useState(false);
  const [stagedNegative, setStagedNegative] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const savedValue: SavedReportDataItem | undefined = savedValues.savedAnswers.find(
    (x) => x.dataItemId === dataItem.dataItemId
  );
  const savedValueItem = (savedValue?.savedReportDataItem as SavedActionListNumeric)?.selectedReportDataItems.find(
    (item) => item.accountName === dataItem.dataItemText
  );
  const savedInServer = serverValues.savedAnswers.find((answer) => answer.dataItemId === dataItem.dataItemId);

  useEffect(() => {
    if (parseInt(savedValueItem?.userValue ?? '0') < 0 || savedValueItem?.userValue === '-') setIsNegative(true);
    else setIsNegative(false);
  }, [savedValueItem?.userValue]);

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if ((/^(-?\d*)$/.test(value) || !Number.isNaN(value)) && value !== '-') setHasError(false);
    setIsActive(false);
  };

  const handleValueUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    let userValue = event.target.value;

    if (userValue.charAt(userValue.length - 1) === ',') {
      return setHasError(true);
    }

    userValue = stripCommas(userValue);

    if (userValue === '-') {
      setStagedNegative(true);
      setHasError(true);
    }

    if (!/^(-?\d*)$/.test(userValue) || Number.isNaN(userValue)) return setHasError(true);

    if (userValue === '' || userValue !== '-') {
      setStagedNegative(false);
      setHasError(false);
    }

    const responseUid = savedValueItem ? savedValueItem.responseUid : `ASP-${crypto.randomUUID()}`;

    const updatedValueItem: SavedActionListNumeric = {
      dataItemId: dataItem.dataItemId,
      selectedReportDataItems: [
        {
          responseUid,
          accountName: dataItem.dataItemText,
          userValue: userValue === '' || userValue === '-' ? '' : parseInt(userValue).toString(),
          delete: userValue === '' || userValue === '-',
        },
      ],
    };

    // First time adding a value for this data item:
    if (savedValue === undefined) {
      const newDataItem: SavedReportDataItem = {
        accountClass: dataItem.accountClass ?? '',
        dataItemId: dataItem.dataItemId,
        isCalculated: false,
        overwrite: false,
        savedReportDataItem: updatedValueItem,
        action: dataItem.action,
        topic: dataItem.topic,
      };
      savedReportDataItems({
        savedAnswers: [...savedValues.savedAnswers, newDataItem],
      });
      // Previous value found for this data item:
      // Remove from staging if value empty and not yet sent to server (user enters and deletes text):
    } else if (userValue === '' && !savedInServer) {
      const excludedSavedReportDataItems = savedValues.savedAnswers.filter(
        (i: SavedReportDataItem) => i.dataItemId !== savedValue.dataItemId
      );
      savedReportDataItems({ savedAnswers: excludedSavedReportDataItems });
      // Update the previously saved data item with new value:
    } else {
      const updatedSavedReportDataItems = savedValues.savedAnswers.map((x: SavedReportDataItem) =>
        x.dataItemId === dataItem.dataItemId
          ? { ...x, overwrite: userValue !== '', savedReportDataItem: updatedValueItem }
          : x
      );

      savedReportDataItems({ savedAnswers: updatedSavedReportDataItems });
    }

    event.target.value = userValue;
  };

  const getPrefix = () => {
    if (isActive) return '$';
    return isNegative ? '-$' : '$';
  };

  const renderValue = (value: string) => {
    if (isActive) {
      return stagedNegative ? '-' : value;
    }
    return formatValueString(value.replace('-', ' '));
  };

  const displayTotal = currencyFormat.format(Number(savedValueItem?.userValue ?? 0));

  if (minified)
    return (
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height="64px"
        fontWeight={600}
        paddingLeft={32}
      >
        <Text>{dataItem.dataItemText}</Text>
        <Text>{displayTotal}</Text>
      </Flex>
    );

  return (
    <>
      <ReportListHeaderTitle title={dataItem.dataItemText} helpTexts={helpTexts} dataItemId={dataItem.dataItemId} />
      <TextField
        className="reportlist-nodsp-input report-input-field"
        label={dataItem.dataItemText}
        innerStartComponent={getPrefix()}
        labelHidden
        onChange={handleValueUpdate}
        value={renderValue(savedValueItem?.userValue ?? '')}
        onBlur={handleOnBlur}
        onFocus={() => {
          setIsActive(true);
        }}
        maxLength={12}
        hasError={hasError}
        errorMessage="Whole numbers only"
      />
    </>
  );
};

export default ReportListNoDsp;
