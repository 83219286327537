import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { useReactiveVar } from '@apollo/client';
import { Card, Image, Flex, Heading, Text, View } from '@aws-amplify/ui-react';
import ValueBackImage from 'assets/value-back/infographic.png';
import IconPopulation from 'assets/value-back/icon-population.svg';
import IconIndustry from 'assets/value-back/icon-industry.svg';
import IconCommunity from 'assets/value-back/icon-community.svg';
import IconEmployment from 'assets/value-back/icon-employment.svg';
import PostcodeSearch from './PostcodeSearch';

import './ValueBackCard.css';

const BenefitIcon = ({ label, src }: { label: string; src: string }) => (
  <div className="benefit-icon">
    <Image src={src} alt="" width="65px" height="65px" />
    {label}
  </div>
);

const ValueBackCard = () => {
  const currentBusinesses = useReactiveVar(authorisedBusinesses);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const postcode: string = currentBusinesses.activeBusiness?.postcode || '';

  return (
    <Flex testId="value-back-component" direction="column" alignItems="stretch" marginTop="15px">
      <Flex alignSelf="stretch" alignItems="center" justifyContent="space-between" gap="0" paddingRight="18px">
        <View className="left-contour-line-primary" padding="40px 0 40px 50px" width="520px" flex="0 0 520px">
          <Heading level={2} fontWeight="600" fontSize="28px" margin="0 0 20px 0">
            More for your business
          </Heading>
          <Text fontSize="19px">Explore our data about people and businesses in your area</Text>
        </View>
        <Flex alignItems="center" justifyContent="center" gap="0" flex="0 0 520px">
          <Image
            testId="value-back-infographic"
            className="value-back-infographic"
            src={ValueBackImage}
            alt="Graphs and charts emerging from a screen. Two people scrutinize the data with a giant magnifying glass."
          />
        </Flex>
      </Flex>
      <Card variation="outlined" borderRadius="8px" border="2px solid #ebeced" paddingLeft="15px" minWidth="1047px">
        <Flex gap="0" alignItems="center" justifyContent="space-between">
          <PostcodeSearch postcode={postcode} />
          <Flex gap="8px" flex="0 0 content" padding="0 4px" alignSelf="flex-start">
            <BenefitIcon label="Population" src={IconPopulation} />
            <BenefitIcon label="Industry" src={IconIndustry} />
            <BenefitIcon label="Community" src={IconCommunity} />
            <BenefitIcon label="Employment" src={IconEmployment} />
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

export default ValueBackCard;
