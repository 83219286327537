/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { Button, Flex, Grid, View } from '@aws-amplify/ui-react';

import { recordRumCustomEvent } from 'services/awsRum';
import { chosenReport } from 'apollo/states/ChosenReport';
import { saveTimer } from 'apollo/states/SaveTimer';
import useCurrentReportSteps, { findNextReportStep } from 'apollo/states/utils/useCurrentReportSteps';
import { isSubmittingReport } from 'apollo/states/operationsInProgress';
import { SideNavbar } from 'components/SideNavbar/SideNavbar';
import ReportSaveStatus from 'components/ReportComponents/ReportSaveStatus/ReportSaveStatus';
import { useSaveReport } from 'utils/useSaveReport';
import useSaveReportWithResponse from 'utils/useSaveReportWithResponse';
import ToastNotification from 'components/ToastNotification/ToastNotification';
import useDspApiErrorToast from 'hooks/useDspApiErrorToast';
import useSyncDspItems from 'hooks/useSyncDspItems';
import { InlineError } from 'components/InlineError/InlineError';
import { saveIntervalSeconds } from 'utils/saveInterval';
import { useSaveReportOptions } from 'utils/getSaveReportOptions';
import { getRumAttributes } from 'utils/getRumAttributes';
import { useSetReportLock } from 'utils/useSetReportLock';
import { RumCustomEvent } from 'enums/RumCustomEvent';

import { ReactComponent as SyncIconSvg } from 'assets/icon-sync.svg';
import { ReactComponent as SaveIconSvg } from 'assets/icon-save.svg';
import { ReactComponent as SavingIconSvg } from 'assets/icon-loading-save.svg';

import './ReportPage.css';

const ReportPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const chosenReportDetails = chosenReport();
  const reportPage = pathname.split('/').at(-1);
  const isConfirmationPage = reportPage === 'confirmation';

  const [isSyncing, startSync] = useSyncDspItems();

  const [syncSuccessNotificationOpen, setSyncSuccessNotificationOpen] = useState(false);
  const [syncFailureNotificationOpen, setSyncFailureNotificationOpen] = useState(false);
  const [saveFailureNotificationOpen, setSaveFailureNotificationOpen] = useState(false);
  const [saveLockedFailureNotificationOpen, setSaveLockedFailureNotificationOpen] = useState(false);

  const [toastToShow, showToastOpenIfDSPError, clearDspApiToast] = useDspApiErrorToast();

  const [callSaveReport, saveReportResponse] = useSaveReportWithResponse();
  const saveReportOptions = useSaveReportOptions();

  const isLocked = useMemo(() => {
    if (chosenReportDetails.singleUserAccess?.lock) {
      setSaveLockedFailureNotificationOpen(true);
      return true;
    }
    return false;
  }, [chosenReportDetails?.singleUserAccess?.lock]);

  const unlockReport = useSetReportLock('unlock');

  const handleSyncClick = () => {
    if (isSyncing) return;
    if (isLocked) return;

    // clear existing notifications to avoid confusion
    setSyncSuccessNotificationOpen(false);
    setSyncFailureNotificationOpen(false);
    clearDspApiToast();

    startSync()
      .then(() => {
        setSyncSuccessNotificationOpen(true);
        // ensure error is not still shown if it was still displaying
        setSyncFailureNotificationOpen(false);
      })
      .catch((errorMessages) => {
        if (!showToastOpenIfDSPError(errorMessages)) {
          setSyncFailureNotificationOpen(true);
        }
        // ensure success is not still shown if it was still displaying
        setSyncSuccessNotificationOpen(false);
      });
  };

  const handleSaveAndExitClick = () => {
    if (isLocked) return;
    setSaveFailureNotificationOpen(false);
    callSaveReport({
      ...saveReportOptions,
      onCompleted: (response) => {
        if (response.saveReport.success) {
          unlockReport();
          recordRumCustomEvent(RumCustomEvent.reportClose, getRumAttributes());
          navigate('/dashboard');
        } else {
          setSaveFailureNotificationOpen(true);
        }
      },
      onError: () => setSaveFailureNotificationOpen(true),
    });
  };

  // clear sync feedback on navigation to a different step
  useEffect(() => {
    setSyncFailureNotificationOpen(false);
    setSyncSuccessNotificationOpen(false);
    setSaveFailureNotificationOpen(false);
  }, [reportPage]);

  useEffect(() => {
    if ((!isConfirmationPage && chosenReportDetails.submitted) || !chosenReportDetails.userReportId)
      navigate('/dashboard', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // If this topic is not in the current report, navigate away
  const currentReportSteps = useCurrentReportSteps();
  useEffect(() => {
    const currentStep = currentReportSteps.find((step) => step.link === pathname);

    if (!currentStep) {
      // Not a report page, navigate to another report page
      const nextStep = findNextReportStep(currentReportSteps, pathname);
      if (nextStep) navigate(nextStep.link, { replace: true });
    }
  }, [pathname, currentReportSteps, navigate]);

  const saveTimeCountdown = useReactiveVar(saveTimer);

  const saveReport = useSaveReport();

  /* istanbul ignore next */
  useEffect(() => {
    const timer = setInterval(() => {
      if (saveIntervalSeconds > saveTimeCountdown.timer) {
        saveTimer({ ...saveTimeCountdown, timer: saveTimeCountdown.timer + 1 });
      } else if (!isConfirmationPage) {
        if (isLocked) return;
        saveReport(false);
        saveTimer({ ...saveTimeCountdown, timer: 0 });
      }
    }, 1000);
    // clearing interval
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveTimeCountdown.timer]);

  // Sends a notification to RUM on browser close
  const handleCloseBrowser = () => {
    recordRumCustomEvent(RumCustomEvent.windowClose, getRumAttributes());
    saveReport(false);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleCloseBrowser);
    return () => window.removeEventListener('beforeunload', handleCloseBrowser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="report">
      <Grid className="report-page-wrapper" columnGap="16px" templateColumns="repeat(12, 1fr)" padding="0 32px">
        {!isConfirmationPage && (
          <View className="" columnStart="1" columnEnd="3">
            <View className="report-side-nav-wrapper">
              <Flex direction="column" gap={32} alignItems="center" width={144} marginBottom={60}>
                {/* Save Status */}
                <ReportSaveStatus />
                {/* Save + Exit / Sync button group */}
                <Flex direction="column" justifyContent="flex-end" gap={8}>
                  {chosenReportDetails.isUsingDsp && (
                    <>
                      <Button
                        testId="sync-btn"
                        onClick={handleSyncClick}
                        className="sync-btn action-btn"
                        isDisabled={saveReportResponse.loading || isSyncing || isSubmittingReport()}
                        justifyContent="space-between"
                      >
                        {isSyncing ? (
                          <>
                            Syncing
                            <SavingIconSvg className="saving-rotate-icon" />
                          </>
                        ) : (
                          <>
                            Sync
                            <SyncIconSvg />
                          </>
                        )}
                      </Button>
                      {syncFailureNotificationOpen && (
                        <InlineError styles="sync-inline-error" errorMessage="Sorry, that button isn't working" />
                      )}
                    </>
                  )}
                  <Button
                    testId="save-exit-btn"
                    onClick={handleSaveAndExitClick}
                    className="action-btn"
                    isDisabled={saveReportResponse.loading || isSyncing || isSubmittingReport()}
                    justifyContent="space-between"
                  >
                    {saveReportResponse.loading ? (
                      <>
                        Saving
                        <SavingIconSvg className="saving-rotate-icon" />
                      </>
                    ) : (
                      <>
                        Save and exit
                        <SaveIconSvg />
                      </>
                    )}
                  </Button>
                  {saveFailureNotificationOpen && (
                    <InlineError styles="sync-inline-error" errorMessage="Sorry, that button isn't working" />
                  )}
                </Flex>
              </Flex>
              {/* Topic links */}
              <SideNavbar />
            </View>
          </View>
        )}
        <View className="report-main-container" columnStart="3" columnEnd="11">
          {!isConfirmationPage && (
            <View className="report-header-container" columnStart="3" columnEnd="-1">
              <Flex justifyContent="space-between">
                <span data-testid="report-header" className="report-header">
                  <span data-testid="report-trading-name" className="trading-name">
                    {chosenReportDetails.organisationName}
                  </span>{' '}
                  |<span data-testid="trading-name"> {chosenReportDetails.reportPeriod} report</span>
                </span>
              </Flex>
            </View>
          )}
          <Outlet />
        </View>
      </Grid>
      <ToastNotification
        success
        title="Sync successful"
        open={syncSuccessNotificationOpen}
        onClose={() => setSyncSuccessNotificationOpen(false)}
      />
      {toastToShow}
      <ToastNotification
        success={false}
        title="Failed to save. The report is locked by another user."
        open={saveLockedFailureNotificationOpen}
        onClose={() => setSaveLockedFailureNotificationOpen(false)}
      />
    </div>
  );
};

export default ReportPage;
