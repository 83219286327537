/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import { GET_DSP_BUSINESS_NAME } from 'apollo/queries/getDspBusinessName';
import { AuthorisedBusinesses, authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { syncingDspBusinessNamesState } from 'apollo/states/operationsInProgress';
import { AuthorisedBusiness } from 'models';
import { GetDspBusinessNameResponse } from 'models/GraphQL/GetDspBusinessNameResponse';
import { AuthorisationRouterState } from 'pages/Authorisation/AuthorisationPage';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { recordRumError } from 'services/awsRum';

const isDsp = (business: AuthorisedBusiness) => business.dsp !== 'NODSP';

/**
 * Splits AuthorizedBusinesses into two objects, distinct by dsp or NODSP
 */
export const splitNoDsp = (businesses: AuthorisedBusinesses) => {
  const dspBusinesses: AuthorisedBusinesses = { activeBusiness: undefined, authorisedBusinesses: [] };
  const noDspBusinesses: AuthorisedBusinesses = { activeBusiness: undefined, authorisedBusinesses: [] };
  if (businesses.activeBusiness && isDsp(businesses.activeBusiness)) {
    dspBusinesses.activeBusiness = businesses.activeBusiness;
  } else {
    noDspBusinesses.activeBusiness = businesses.activeBusiness;
  }
  dspBusinesses.authorisedBusinesses = businesses.authorisedBusinesses.filter(isDsp);
  noDspBusinesses.authorisedBusinesses = businesses.authorisedBusinesses.filter((business) => !isDsp(business));
  return {
    dspBusinesses,
    noDspBusinesses,
  };
};

export interface GetDspBusinessNameVariables extends OperationVariables {
  dsp: string;
  organisationId: string;
}

export const getSyncDspBusinessNameVars = (businesses: AuthorisedBusinesses): GetDspBusinessNameVariables[] =>
  businesses.authorisedBusinesses
    .map((business) => {
      if (business?.dsp !== 'NODSP' && business?.id) {
        return {
          dsp: business.dsp,
          organisationId: business.id,
        };
      }
      return null;
    })
    .filter((obj): obj is GetDspBusinessNameVariables => !!obj);

/**
 * Updates the local authorized business connections state to reflect the latest BE state.
 *
 * This prevents the user from seeing businesses that are no longer connected,
 * and subsequently, undefined.
 */
export const useSyncDspBusinessNames = () => {
  const navigate = useNavigate();
  const [results, setResults] = useState<QueryResult<GetDspBusinessNameResponse, OperationVariables>[]>([]);
  const [callQuery] = useLazyQuery(GET_DSP_BUSINESS_NAME, {
    fetchPolicy: 'no-cache',
    onError: (error) => recordRumError(error),
  });

  const call = async (businesses: AuthorisedBusinesses) => {
    const { dspBusinesses, noDspBusinesses } = splitNoDsp(businesses);
    const variables = getSyncDspBusinessNameVars(businesses);
    syncingDspBusinessNamesState(true);
    const callResults = await Promise.all(variables.map((vars) => callQuery({ variables: vars })));
    setResults(results);
    const newDspAuthorisedBusinesses = { ...dspBusinesses };
    // loop over the variables and get the respective query response data
    variables.forEach(({ dsp, organisationId }, i) => {
      const response = callResults?.[i]?.data?.getDspBusinessName;

      const responseDspBusinessName = response?.dspBusinessName;

      const matchedBusiness = businesses.authorisedBusinesses.find(
        (business) => business?.dsp === dsp && business?.id === organisationId
      );
      const matchedActiveBusiness = [businesses.activeBusiness].find(
        (business) => business?.dsp === dsp && business?.id === organisationId
      );

      // if the local business is disconnected
      if (!responseDspBusinessName) {
        // delete the disconnected business
        delete newDspAuthorisedBusinesses.authorisedBusinesses[i];

        // if variables and response are also for the active business
        if (matchedActiveBusiness) {
          newDspAuthorisedBusinesses.activeBusiness = undefined;
        }
      }
      // else if the local business is different to the BE business, we must update it.
      else if (matchedBusiness && responseDspBusinessName !== matchedBusiness?.dspBusinessName) {
        // update the local state with the new dspBusinesName from the BE.
        newDspAuthorisedBusinesses.authorisedBusinesses[i] = {
          ...matchedBusiness,
          dspBusinessName: responseDspBusinessName,
        };

        // if variables and response are also for the active business, we must update it too
        if (matchedActiveBusiness) {
          newDspAuthorisedBusinesses.activeBusiness = {
            ...matchedActiveBusiness,
            dspBusinessName: responseDspBusinessName,
          };
        }
      }
    });

    // remove falsey(disconnected) businesses
    newDspAuthorisedBusinesses.authorisedBusinesses = newDspAuthorisedBusinesses.authorisedBusinesses.filter(
      (item) => item
    );

    // We now need to remerge the nodsp businesses before updating the state
    const mergedBusinesses: AuthorisedBusinesses = {
      activeBusiness: noDspBusinesses.activeBusiness ?? newDspAuthorisedBusinesses.activeBusiness,
      authorisedBusinesses: [
        ...newDspAuthorisedBusinesses.authorisedBusinesses,
        ...noDspBusinesses.authorisedBusinesses,
      ],
    };
    // if there are no authorized businesses
    if (!mergedBusinesses.authorisedBusinesses.length) {
      authorisedBusinesses({ activeBusiness: undefined, authorisedBusinesses: [] });
      navigate('/authorisation', {
        state: { addingBusiness: true, noAuthorisedBusinesses: true } as AuthorisationRouterState,
      });
    }
    // if the active business is now removed, we need to set the top authorised business as the activeBusiness
    else if (!mergedBusinesses.activeBusiness) {
      authorisedBusinesses({
        activeBusiness: mergedBusinesses.authorisedBusinesses?.[0],
        authorisedBusinesses: mergedBusinesses.authorisedBusinesses,
      });
    } else {
      authorisedBusinesses(mergedBusinesses);
    }
    syncingDspBusinessNamesState(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return [call, results] as const;
};
