import { gql } from '@apollo/client';

export const LOCK_USER_REPORT = gql`
  mutation lockUserReport($dspProvider: String!, $lock: Boolean!, $organisationId: String!, $userReportId: String!) {
    lockUserReport(
      dspProvider: $dspProvider
      lock: $lock
      organisationId: $organisationId
      userReportId: $userReportId
    ) {
      success
      errorMessages {
        message
        errorCode
        language
        metaData
      }
    }
  }
`;
