/* istanbul ignore file */
import { DefaultContext, MutationFunctionOptions, OperationVariables, useMutation } from '@apollo/client';
import { SavedReportDataItem, SaveReportResponse } from 'models';
import { recordRumError } from 'services/awsRum';
import { SAVE_REPORT } from 'apollo/mutations/saveReport';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { resetLastSavedTimer, saveTimer } from 'apollo/states/SaveTimer';
import {
  isSubmittingReport,
  savingLocalAnswersStatus,
  savingLocalAnswersToServer,
  syncingDspItems,
} from 'apollo/states/operationsInProgress';
import { getSaveReportOptions } from './getSaveReportOptions';
import { saveIntervalSeconds } from './saveInterval';
import { useSetReportLock } from './useSetReportLock';

/**
 * Used to save report but not awaiting result from api call
 * @param updateLatest by default updates local state after save. Use false when navigating away from report on save call
 */
export const useSaveReport = (updateLatest = true) => {
  const unlockReport = useSetReportLock('unlock');

  const handleCompleted = (
    response: SaveReportResponse,
    clientOptions?: MutationFunctionOptions<SaveReportResponse, OperationVariables, DefaultContext>
  ) => {
    if (response.saveReport.success) {
      savingLocalAnswersStatus('saved');
      const savedAnswers = (clientOptions?.context?.savedAnswers as SavedReportDataItem[]) ?? [];
      if (updateLatest) latestSavedReportDataItems({ savedAnswers });
      if (clientOptions?.context?.partialSave) {
        // If there is more to save, ensure next save starts soon
        resetLastSavedTimer(saveIntervalSeconds - 1);
      } else {
        resetLastSavedTimer();
      }
    } else {
      savingLocalAnswersStatus('error');
    }
    savingLocalAnswersToServer(false);
  };

  const [saveReportToServer] = useMutation<SaveReportResponse>(SAVE_REPORT, {
    onCompleted: handleCompleted,
    onError: (error) => {
      recordRumError(error);
      savingLocalAnswersStatus('error');
    },
  });

  /**
   * Figure out what to save, and trigger the API call.
   * If no changes have been made and lockReport is false, the report is unlocked.
   */
  const beginSave = (lockReport?: boolean) => {
    const saveReportOptions = getSaveReportOptions(lockReport);
    if (saveReportOptions.variables.reportDataObject.reportDataItems?.length) {
      savingLocalAnswersToServer(true);
      savingLocalAnswersStatus('saving');
      saveReportToServer(saveReportOptions);
    } else if (lockReport === false) {
      unlockReport();
    }
  };

  /**
   * Returns a function that initiates the save report process.
   * @param lockReport This optional variable toggles the lock state of the report(determines whether other users can edit the report at the same time).
   * If omitted, the lock state will not change.
   */
  return (lockReport?: boolean) => {
    // Prevent saving while submitting.
    if (isSubmittingReport()) {
      return;
    }
    if (!syncingDspItems()) {
      beginSave(lockReport);
      return;
    }

    // Ensure timer will not trigger additional saves while this is waiting (controlled by interval in ReportPage)
    // this will work as long as sync takes under 60s
    saveTimer({ ...saveTimer(), timer: 0 });

    const saveOrWait = (syncing: boolean) => {
      if (syncing) {
        // Keep listening for changes until not syncing
        syncingDspItems.onNextChange(saveOrWait);
      } else {
        beginSave(lockReport);
      }
    };

    syncingDspItems.onNextChange(saveOrWait);
  };
};
