import { useState, useEffect } from 'react';
import { View, Text } from '@aws-amplify/ui-react';
import { IIdleTimer } from 'react-idle-timer';

const CountdownClock = ({ timer, promptTimeout }: { timer: IIdleTimer; promptTimeout: number }) => {
  const [countdown, setCountdown] = useState(promptTimeout);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(Math.ceil(timer.getRemainingTime() / 1000));
    }, 500);

    return () => clearInterval(interval);
  });

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <View
      style={{
        width: '144px',
        height: '144px',
        backgroundColour: 'red',
        margin: '36px auto 44px auto',
      }}
    >
      <Text className="countdown-text">{formatTime(countdown)}</Text>
      <svg visibility={timer.getRemainingTime() > 0 ? '' : 'hidden'} className="timeout-svg">
        <circle r="66" cx="72" cy="72" className="timeout-circle-track" />
        <circle
          r="66"
          cx="72"
          cy="72"
          style={{
            animation: `countdown ${promptTimeout}s linear infinite forwards`,
          }}
          shapeRendering="geometricPrecision"
          className="timeout-circle"
        />
      </svg>
    </View>
  );
};

export default CountdownClock;
