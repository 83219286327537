import { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import {
  Button,
  CheckboxField,
  Flex,
  Heading,
  Image,
  SearchField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  View,
  VisuallyHidden,
} from '@aws-amplify/ui-react';
import CustomAmplifyTheme from 'assets/CustomAmplifyTheme';
import { InlineError } from 'components/InlineError/InlineError';
import SearchIconSvg from 'assets/icon-search.svg';
import { ReactComponent as CloseIconSvg } from 'assets/icon-x.svg';
import { currencyFormat } from 'utils/CurrencyFormat';
import { modalMarginFix } from 'utils/ModalMarginFix';

import { getDsp } from 'utils/dspIndex';
import { DataItem, DspDataItemObject, SavedActionListNumeric, SavedActionListNumericItem } from 'models';
import { useReactiveVar } from '@apollo/client';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { dspAccountsCustomSort } from 'utils/dspAccountCustomSort';
import { CSSObject } from '@emotion/react';

import { recordRumCustomEvent } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';

const unMappedNumOfCols = 4;
const unMappedColWidths = {
  checkBox: '10%',
  account: '50%',
  amount: '40%',
};

// Conditional for tests. Workaround as per https://github.com/reactjs/react-modal/issues/632
/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const customStyles: Record<string, CSSObject> = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '768px',
    minWidth: '320px',
    maxHeight: '100%',
    border: 'solid 1px #ebeced',
    borderRadius: '8px',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingBottom: '2rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  overlay: {
    backgroundColor: 'rgba(75, 79, 84, 0.17)',
    zIndex: '10000',
  },
};

/**
 * Modal for adding accounts from DSP
 */
export const AddAccountsModal = ({
  show,
  title,
  dspId,
  dspItems,
  dataItem,
  onClose,
  allocateAccounts,
}: {
  show: boolean;
  title: string;
  dspId: string;
  dspItems: DspDataItemObject[];
  dataItem: DataItem;
  onClose: () => unknown;
  allocateAccounts: (selectedAccounts: SavedActionListNumericItem[]) => unknown;
}) => {
  const dspModalLogo = useMemo((): string => getDsp(dspId).addFromModalIcon, [dspId]);
  const [inlineError] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [selectedAccounts, setSelectedAccounts] = useState<SavedActionListNumericItem[]>([]);

  const savedItems = useReactiveVar(savedReportDataItems);

  // DSP accounts mapped to *any* data item (regardless of topic)
  const mappedDspItems = useMemo(
    () =>
      savedItems.savedAnswers
        .filter((i) => i.action?.match(/list/i))
        .flatMap((i) => (i.savedReportDataItem as SavedActionListNumeric).selectedReportDataItems),
    [savedItems.savedAnswers]
  );

  const loadDspItems = () => {
    const unmappedItems = dspItems.filter((i) => !mappedDspItems.map((x) => x.responseUid).includes(i.responseUid));

    return [
      !unmappedItems.length,
      unmappedItems.length,
      unmappedItems
        .filter(
          (i) =>
            // account name
            i.accountName.toLowerCase().includes(query.toLowerCase()) ||
            // item code
            i.code?.toLowerCase().includes(query.toLowerCase())
        )
        .map(
          (item): SavedActionListNumericItem => ({
            accountName: item.accountName,
            class: item.class,
            code: item.code,
            dspValue: item.dspValue,
            mapState: true,
            overwrite: false,
            responseUid: item.responseUid,
            dspDateRead: item.dspDateSave,
            type: item.type,
            suggestForDataItem: item.suggestForDataItem,
          })
        )
        .sort(dspAccountsCustomSort(dataItem.dataItemId)),
    ] as const;
  };
  const [allAccountsMapped, , filteredAccounts] = useMemo(loadDspItems, [dspItems, dataItem, query, mappedDspItems]);

  /* istanbul ignore next */
  const handleItemChange = (e: any) => {
    const input = e.target as HTMLInputElement;
    const item = filteredAccounts.find((x) => x.responseUid === input.value);
    if (item != null) {
      if (input.checked) {
        setSelectedAccounts((prev) => [...prev, item]);
      } else {
        setSelectedAccounts((prev) => [...prev.filter((x) => x.responseUid !== item.responseUid)]);
      }
    }
  };

  const handleCheckAll = () => {
    const isAllChecked = filteredAccounts.every((x) =>
      selectedAccounts.find((selectedAccount) => selectedAccount.responseUid === x.responseUid)
    );
    if (isAllChecked) {
      recordRumCustomEvent(RumCustomEvent.allocateModalDeselectAll, getRumAttributes());
      setSelectedAccounts((prev) => prev.filter((i) => !filteredAccounts.find((j) => j.responseUid === i.responseUid)));
    } else {
      recordRumCustomEvent(RumCustomEvent.allocateModalSelectAll, getRumAttributes());
      const notYetSelectedAccounts = filteredAccounts.filter((i) =>
        selectedAccounts.every((j) => j.responseUid !== i.responseUid)
      );
      const newSelectedAccounts = [...selectedAccounts, ...notYetSelectedAccounts];

      setSelectedAccounts(newSelectedAccounts);
    }
  };

  const allSelected = useMemo(
    () =>
      !!filteredAccounts.length &&
      filteredAccounts.every((x) =>
        selectedAccounts.find((selectedAccount) => selectedAccount.responseUid === x.responseUid)
      ),
    [selectedAccounts, filteredAccounts]
  );

  useEffect(() => {
    if (show) {
      modalMarginFix('open');
    } else {
      setSelectedAccounts([]);
      setQuery('');
      modalMarginFix('close');
    }
  }, [show]);

  const handleAllocate = () => {
    if (selectedAccounts.length) {
      allocateAccounts(selectedAccounts);
    } else {
      onClose();
    }
  };

  const filtering = !!query.length;
  const alwaysShowSelectAll = dataItem.showSelectAllWhenAccountsNotFiltered;
  const hideSelectAll = !alwaysShowSelectAll && !(filtering && filteredAccounts.length);

  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc
      testId="add-from-dsp-modal"
      aria={{ labelledby: 'add-from-dsp-modal-header', modal: true }}
    >
      <CustomAmplifyTheme isModal>
        <Flex direction="column" className="modal-gap">
          <Button className="close-icon-btn" variation="link" onClick={onClose} testId="add-from-dsp-modal-close">
            <span className="amplify-visually-hidden">Close Modal</span>
            <CloseIconSvg />
          </Button>
          <Flex className="modal-logo" wrap="wrap">
            <Image alt="logo" src={dspModalLogo} maxWidth="200px" maxHeight="46px" />
          </Flex>
          <Flex className="modal-content" justifyContent="space-between" alignItems="center" wrap="wrap">
            <Heading id="add-from-dsp-modal-header" level={3} className="add-from-dsp-heading" testId="modal-heading">
              {title}
            </Heading>
            <View className="add-from-dsp-items-filter">
              <span id="add-accounts-filter-label">Filter by account code or name</span>
              <SearchField
                width="100%"
                testId="filter-items-input"
                aria-describedby="screen-reader-search-description"
                label="Filter Accounts"
                borderRadius="8px"
                outerEndComponent=""
                maxLength={250}
                innerStartComponent={
                  <Image
                    alt=""
                    src={SearchIconSvg}
                    height="20px"
                    width="20px"
                    margin="10px 10px 10px 10px"
                    testId="filter-icon"
                  />
                }
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onClear={() => setQuery('')}
              />
              <VisuallyHidden>
                <div aria-live="polite" aria-hidden="true" id="screen-reader-search-description">
                  {query.length === 0
                    ? 'Accounts will be filtered below the field as you type'
                    : `${filteredAccounts.length} ${filteredAccounts.length === 1 ? 'result' : 'results'} found`}
                </div>
              </VisuallyHidden>
            </View>
          </Flex>
          {inlineError && <InlineError />}
          {/* Table displaying unmapped accounts available to add  */}
          <View
            style={{
              overflowX: 'auto',
            }}
          >
            <Table id="add-from-dsp" className="add-from-dsp-table" testId="add-from-dsp-table">
              <TableHead>
                <TableRow testId="add-dsp-table-head-row" className="add-from-dsp-table">
                  <TableCell className="wrap-cell-content" as="th" width={unMappedColWidths.checkBox}>
                    <CheckboxField
                      label="Select All"
                      name="Select All"
                      value="Add"
                      onChange={handleCheckAll}
                      size="large"
                      labelHidden
                      checked={allSelected}
                      testId="filter-checkbox-all"
                      isDisabled={filteredAccounts.length === 0 || hideSelectAll}
                    />
                  </TableCell>
                  <TableCell as="th" width={unMappedColWidths.account} testId="filter-header-account">
                    Account
                  </TableCell>
                  <TableCell as="th" width={unMappedColWidths.amount} textAlign="right" testId="filter-header-amount">
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="add-from-dsp-tbody" className="scroll-shadows">
                {filteredAccounts.length ? (
                  filteredAccounts.map((item) => (
                    <TableRow testId="add-dsp-table-body-row" key={item.responseUid}>
                      <TableCell className="wrap-cell-content" width={unMappedColWidths.checkBox}>
                        <CheckboxField
                          name="Select"
                          value={item.responseUid}
                          onChange={(e) => handleItemChange(e)}
                          type="checkbox"
                          checked={!!selectedAccounts.find((x) => x.responseUid === item.responseUid)}
                          label={`Select account ${item.code} ${item.accountName}`}
                          labelHidden
                          size="large"
                          testId="filter-checkbox"
                        />
                      </TableCell>
                      <TableCell width={unMappedColWidths.account} testId="table-cell-account">
                        <span className="account-name">{item.accountName}</span>
                        <br />
                        <span className="account-code">{item.code}</span>
                      </TableCell>
                      <TableCell textAlign="right" width={unMappedColWidths.amount} testId="table-cell-amount">
                        {currencyFormat.format(Number(item.dspValue))}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow height="100%">
                    <TableCell
                      className="report-list-empty-items-cell"
                      testId="table-all-allocated"
                      colSpan={unMappedNumOfCols}
                      margin="auto"
                    >
                      {allAccountsMapped ? 'All accounts have been allocated' : 'No accounts match this search'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </View>
          <Flex className="button-box">
            <Button
              variation="primary"
              onClick={handleAllocate}
              testId="add-from-dsp-modal-allocate"
              className="allocate-accounts-btn"
            >
              Allocate {selectedAccounts.length} {selectedAccounts.length === 1 ? 'account' : 'accounts'}
            </Button>
          </Flex>
        </Flex>
      </CustomAmplifyTheme>
    </Modal>
  );
};
