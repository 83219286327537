import { gql } from '@apollo/client';

export const SUBMIT_REPORT = gql`
  mutation SubmitReport($dspProvider: String, $reportDataObject: ReportDataObject, $organisationId: String) {
    submitReport(dspProvider: $dspProvider, reportDataObject: $reportDataObject, organisationId: $organisationId) {
      success
      pdfUrl
      errorMessages {
        errorCode
        language
        message
        metaData
      }
    }
  }
`;
