/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useReactiveVar } from '@apollo/client';
import {
  Card,
  Expander,
  ExpanderItem,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableRow,
  Text,
} from '@aws-amplify/ui-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import {
  ExpandedReview,
  ExpandedReviewClicked,
  addToExpandersArray,
  updateExpandersArray,
} from 'apollo/states/ExpandedReview';
import {
  DspDataItemObject,
  SavedActionListNumeric,
  SavedActionListNumericItem,
  SavedReportDataItem,
  ManualItem,
  DataItem,
} from 'models';
import { currencyFormat } from 'utils/CurrencyFormat';
import { getActionResultAndLabel } from 'utils/GetActionResultAndLabel';
import { getDsp } from 'utils/dspIndex';
import { dspAccountsCustomSort } from 'utils/dspAccountCustomSort';
import TopRemainingReview, { getTopTwoRemainingItems } from 'components/ReportComponents/TopRemaining/TopRemaining';

import { ReactComponent as TopRemainingIconSvg } from 'assets/icon-asterix-blue.svg';

import { recordRumCustomEvent } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { TopicName } from 'models/LocalState/TopicFullDetail';

import { AddAccountsModal } from './AddAccountsModal';
import MoreMenu from './MoreMenu';
import RemoveButton from './RemoveButton';
import { ManualAddRow } from './ManualAddRow';
import { ReportListHeader } from './ReportListHeader';
import './ReportList.scss';

const mappedNumOfCols = 5;
const mappedColWidths = {
  code: '20%',
  account: '35%',
  source: '15%',
  amount: '20%',
  action: '10%',
};

export enum ReportListActions {
  SUM = 'LISTSUM',
  COUNT = 'LISTCOUNT',
  AVG = 'LISTAVG',
}

const getActionType = (action: string): ReportListActions => {
  switch (action) {
    case 'LISTSUM':
      return ReportListActions.SUM;
    case 'LISTCOUNT':
      return ReportListActions.COUNT;
    case 'LISTAVG':
      return ReportListActions.AVG;
    default:
      return ReportListActions.COUNT;
  }
};

export interface ReportListProps {
  dspItems: DspDataItemObject[];
  helpTexts?: string[];
  accountTypes: TopicName;
  dspId: string;
  dataItem: DataItem;
  minified?: boolean;
  sortDisplay?: boolean;
}

const ReportList = (props: ReportListProps) => {
  const { dspItems, helpTexts, accountTypes, dspId, dataItem, minified, sortDisplay } = props;
  const { action: actionRaw, accountClass: accountClassRaw, dataItemId, dataItemText: title } = dataItem;

  const accountClass = accountClassRaw || '';
  const action = getActionType(actionRaw);

  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const manualEntryButtonRef = useRef<HTMLButtonElement>(null);

  const {
    control,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors },
  } = useForm<ManualItem>({ mode: 'onChange' });

  // // Set variables to expose state relevant to the list-item being rendered
  // ALL report data items
  const savedItems = useReactiveVar(savedReportDataItems);
  // THIS data item
  const savedItem = useMemo(
    () => savedItems.savedAnswers.find((x) => x.dataItemId === dataItemId),
    [dataItemId, savedItems.savedAnswers]
  );
  // The saved values for THIS item (we have to cast due to multiple types available
  //   but are confident to do so because we filter for LISTXXX items in parent)
  const savedItemValues = savedItem?.savedReportDataItem as SavedActionListNumeric;

  const handleOpenModal = () => {
    recordRumCustomEvent(`${RumCustomEvent.allocateModalOpen(dataItemId)}`, getRumAttributes());
    setShowAddItemModal(true);
  };

  const handleCloseModal = () => {
    recordRumCustomEvent(RumCustomEvent.allocateModalClose(dataItemId), getRumAttributes());
    setShowAddItemModal(false);
  };

  /* istanbul ignore next */
  const addToMappedAccounts = useCallback(
    (newAccounts: SavedActionListNumericItem[]) => {
      // Create a state object with the selected accounts
      const accountsToSave: SavedActionListNumeric = {
        selectedReportDataItems: [...newAccounts],
        dataItemId,
        action,
        dataItemText: title,
      };

      // IF there are no answers for this question in the state (i.e. first time mapping):
      if (savedItem === undefined) {
        // Create a new answer
        const newItemAnswer: SavedReportDataItem = {
          dataItemId,
          accountClass,
          isCalculated: false,
          savedReportDataItem: accountsToSave,
          topic: accountTypes,
          action: action.toString(),
          dataItemText: title,
          overwrite: false,
        };
        // Push this answer to the state array
        savedReportDataItems({ savedAnswers: [...savedItems.savedAnswers, newItemAnswer] });

        // ELSE we need to update the existing saved answers for this question:
      } else {
        // Create a new mapped account list by merging existing mapped accounts with current selection
        const updatedMapped: SavedActionListNumeric = {
          selectedReportDataItems: [
            ...accountsToSave.selectedReportDataItems,
            ...savedItemValues.selectedReportDataItems.filter(
              (i) => !accountsToSave.selectedReportDataItems.map((x) => x.responseUid).includes(i.responseUid)
            ),
          ],
          dataItemId,
          action,
          dataItemText: title,
        };
        // Create a new answers object by merging existing answers with updated current answer
        const updatedSavedReportDataItems = savedItems.savedAnswers.map((i: SavedReportDataItem) =>
          i.dataItemId === dataItemId ? { ...i, savedReportDataItem: updatedMapped } : i
        );
        savedReportDataItems({ savedAnswers: updatedSavedReportDataItems });
      }
    },
    [
      accountClass,
      accountTypes,
      action,
      dataItemId,
      savedItem,
      savedItemValues?.selectedReportDataItems,
      savedItems?.savedAnswers,
      title,
    ]
  );

  const updateMappedAccount = useCallback(
    (updatedItem: SavedActionListNumericItem) => {
      const updatedMapped: SavedActionListNumeric = {
        dataItemId,
        action,
        dataItemText: title,
        selectedReportDataItems: [
          ...savedItemValues.selectedReportDataItems.map((i) =>
            i.responseUid === updatedItem.responseUid ? updatedItem : i
          ),
        ],
      };
      // Create a new answers object by merging existing answers with updated current answer
      const updatedSavedReportDataItems = savedItems.savedAnswers.map((i: SavedReportDataItem) =>
        i.dataItemId === dataItemId ? { ...i, savedReportDataItem: updatedMapped } : i
      );
      savedReportDataItems({ savedAnswers: updatedSavedReportDataItems });
    },
    [action, dataItemId, savedItemValues?.selectedReportDataItems, savedItems?.savedAnswers, title]
  );

  const handleAllocateAccounts = (accounts: SavedActionListNumericItem[]) => {
    addToMappedAccounts(Array.from(new Set(accounts)));
    recordRumCustomEvent(RumCustomEvent.allocateModalAddAccounts(dataItemId), getRumAttributes());
    handleCloseModal();
  };

  const handleRemoveItem = useCallback(
    (id: string) => {
      // Check if we have an answer for this question... Might not be needed!!
      if (savedItem) {
        // Check if the item exists in the mapped accounts list
        const item = savedItemValues.selectedReportDataItems.find((i) => i.responseUid === id);
        if (item) {
          recordRumCustomEvent(
            item?.dspValue
              ? RumCustomEvent.removeAllocatedDspAccount(dataItemId)
              : RumCustomEvent.removeManualEntryAccount(dataItemId),
            getRumAttributes()
          );
          // Create a new mapped account list by filtering out the one to be deleted
          const updatedMapped: SavedActionListNumeric = {
            dataItemId,
            action,
            dataItemText: title,
            selectedReportDataItems: [...savedItemValues.selectedReportDataItems.filter((i) => i !== item)],
          };
          // Create a new answers object by merging existing answers with updated current answer
          const updatedSavedReportDataItems = savedItems.savedAnswers.map((i: SavedReportDataItem) =>
            i.dataItemId === dataItemId ? { ...i, savedReportDataItem: updatedMapped } : i
          );
          savedReportDataItems({ savedAnswers: updatedSavedReportDataItems });
        }
      }
    },
    [action, dataItemId, savedItem, savedItemValues?.selectedReportDataItems, savedItems?.savedAnswers, title]
  );

  const [editingItem, setEditingItem] = useState<string | undefined>('');
  const handleEditItem = (item: ManualItem) => {
    reset(item);
    setEditingItem(item.accountUid);
    setTimeout(() => setFocus('account', { shouldSelect: true }));
  };

  const [addingManualItem, setAddingManualItem] = useState(false);
  const handleManualEntry = () => {
    setAddingManualItem(true);
    setTimeout(() => setFocus('account', { shouldSelect: true }));
  };

  const editingOrAdding = addingManualItem || editingItem !== '';

  const handleCancelEditItem = useCallback(
    (addingItem = false) => {
      setAddingManualItem(false);
      setEditingItem('');
      reset({});
      if (addingItem) setTimeout(() => manualEntryButtonRef?.current?.focus());
    },
    [reset]
  );

  /* istanbul ignore next */
  const handleSaveManualItem = useCallback(
    (data: ManualItem) => {
      const currentItem = savedItemValues?.selectedReportDataItems.find((i) => i.responseUid === data.accountUid);
      // IF we already have this item, update its name and amount
      if (currentItem) {
        // Update the existing item
        const updatedItem: SavedActionListNumericItem = {
          ...currentItem,
          userValue: data.amount,
          accountName: data.account,
        };

        // Update the object in the current mapped accounts for this question
        updateMappedAccount(updatedItem);
        handleCancelEditItem();
        // ELSE create a new item
      } else {
        // Add the new item
        const newManualItem: SavedActionListNumericItem = {
          accountName: data.account.trim(),
          userValue: data.amount,
          responseUid: `ASP-${crypto.randomUUID()}`,
          class: accountClass,
          overwrite: false,
        };

        // Add the new object to the current mapped accounts for this question
        addToMappedAccounts([newManualItem]);
        handleCancelEditItem(true);
      }
    },
    [
      accountClass,
      addToMappedAccounts,
      handleCancelEditItem,
      savedItemValues?.selectedReportDataItems,
      updateMappedAccount,
    ]
  );

  const { actionLabel, actionResult } = useMemo(
    () => getActionResultAndLabel(action, savedItemValues?.selectedReportDataItems),
    [action, savedItemValues?.selectedReportDataItems]
  );

  const topTwoRemainingItems = useMemo(
    () => getTopTwoRemainingItems(savedItems, accountTypes),
    [accountTypes, savedItems]
  );

  const sortedSelectedReportDataItems = useMemo(() => {
    if (savedItemValues) {
      const sortedByCode = savedItemValues.selectedReportDataItems.sort(dspAccountsCustomSort());
      if (sortDisplay && !minified)
        return [
          ...topTwoRemainingItems,
          ...sortedByCode.filter(
            (item) => !topTwoRemainingItems.find((topItem) => topItem.responseUid === item.responseUid)
          ),
        ];
      return sortedByCode;
    }
  }, [minified, savedItemValues, sortDisplay, topTwoRemainingItems]);

  const showAsterisk = (savedActionItem: SavedActionListNumericItem) =>
    sortDisplay && topTwoRemainingItems.find((topItem) => topItem.responseUid === savedActionItem.responseUid);

  const ReportListTable = (
    <Table className="mapped-items-table" testId="report-list-table">
      <TableHead>
        <TableRow>
          <TableCell as="th" testId="mapped-table-header-code" width={mappedColWidths.code}>
            Code
          </TableCell>
          <TableCell as="th" testId="mapped-table-header-account" width={mappedColWidths.account}>
            Account
          </TableCell>
          <TableCell as="th" testId="mapped-table-header-source" width={mappedColWidths.source}>
            Source
          </TableCell>
          <TableCell
            as="th"
            className="wrap-cell-content"
            testId="mapped-table-header-amount"
            textAlign="right"
            width={mappedColWidths.amount}
          >
            Amount
          </TableCell>
          {!minified && (
            <TableCell
              as="th"
              className="report-list-action-th wrap-cell-content"
              testId="mapped-table-header-action"
              width={mappedColWidths.action}
            >
              <span>Action</span>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {addingManualItem && (
          <ManualAddRow control={control} errors={errors} handleCancelManualItem={() => handleCancelEditItem(true)} />
        )}
        {!addingManualItem && (savedItemValues?.selectedReportDataItems.length || 0) === 0 ? (
          <TableRow className="report-list-empty-items-row">
            <TableCell
              className="report-list-empty-items-cell"
              testId="mapped-table-no-accounts"
              colSpan={mappedNumOfCols}
            >
              No accounts have been allocated
            </TableCell>
          </TableRow>
        ) : (
          sortedSelectedReportDataItems?.map((item, i) =>
            item.responseUid === editingItem ? (
              <ManualAddRow key={i} control={control} errors={errors} handleCancelManualItem={handleCancelEditItem} />
            ) : (
              <TableRow key={i}>
                <TableCell testId="mapped-table-cell-code">{item.code}</TableCell>
                <TableCell testId="mapped-table-cell-account">
                  {item.accountName}
                  {showAsterisk(item) && <TopRemainingIconSvg style={{ marginLeft: '4px', verticalAlign: 'super' }} />}
                </TableCell>
                <TableCell testId="mapped-table-cell-source">
                  {item.dspValue ? getDsp(dspId).displayName : 'Manual'}
                </TableCell>
                <TableCell testId="mapped-table-cell-amount" textAlign="right">
                  {currencyFormat.format(Number(item.dspValue) || Number(item.userValue) || 0)}
                </TableCell>
                {!minified && (
                  <TableCell testId="mapped-table-cell-action" textAlign="center" padding="0 16px">
                    {item.dspValue ? (
                      <RemoveButton removeCallback={() => handleRemoveItem(item.responseUid)} />
                    ) : (
                      <MoreMenu
                        editCallback={() =>
                          handleEditItem({
                            accountUid: item.responseUid,
                            account: item.accountName || '',
                            amount: item.userValue!.toString(),
                          })
                        }
                        removeCallback={() => handleRemoveItem(item.responseUid)}
                        editDisabled={editingOrAdding}
                      />
                    )}
                  </TableCell>
                )}
              </TableRow>
            )
          )
        )}
      </TableBody>
      {!minified && (
        <TableFoot>
          <TableRow
            className={
              [0, '0'].includes(actionResult) ? 'report-list-summary-cell-empty-value' : 'report-list-summary-cell'
            }
          >
            <TableCell
              className="report-list-summary-total-table-cell"
              colSpan={mappedNumOfCols - 1}
              testId="table-cell-total"
            >
              {`${actionLabel}: ${actionResult}`}
            </TableCell>
          </TableRow>
        </TableFoot>
      )}
    </Table>
  );

  const allExpanded = useReactiveVar(ExpandedReview);
  const allExpandedClicked = useReactiveVar(ExpandedReviewClicked);
  const [individualExpanded, setIndividualExpanded] = useState(false);

  useEffect(() => {
    if (minified) {
      addToExpandersArray(dataItemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIndividualExpanded(allExpanded);
    updateExpandersArray(dataItemId, allExpanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExpandedClicked]);

  // Report page view
  if (minified) {
    return (
      <Expander
        type="single"
        isCollapsible
        value={individualExpanded ? 'item' : ''}
        onValueChange={() => {
          if (ExpandedReview()) ExpandedReview(false);
          setIndividualExpanded((current) => !current);
          updateExpandersArray(dataItemId, !individualExpanded);
          if (!individualExpanded)
            recordRumCustomEvent(RumCustomEvent.expandIndividualSection, {
              dataItemId,
              ...getRumAttributes(),
            });
        }}
      >
        <ExpanderItem
          className="report-list-expander"
          value="item"
          title={
            <Flex width="100%" justifyContent="space-between">
              <Text>{title}</Text>
              <Text>{actionResult}</Text>
            </Flex>
          }
        >
          {(savedItemValues?.selectedReportDataItems.length || 0) === 0 ? (
            <Text className="no-accounts-minified">No accounts have been allocated</Text>
          ) : (
            <>
              {ReportListTable}
              {sortDisplay && <TopRemainingReview accountType={accountTypes} />}
            </>
          )}
        </ExpanderItem>
      </Expander>
    );
  }

  return (
    <Card className="report-list-container">
      <ReportListHeader
        minified={minified}
        title={title}
        dataItemId={dataItemId}
        helpTexts={helpTexts}
        dspId={dspId}
        onAddFromDsp={handleOpenModal}
        onAddManualEntry={handleManualEntry}
        editingOrAdding={editingOrAdding}
        manualEntryButtonRef={manualEntryButtonRef}
      />
      {/* Help text for top remaining items  */}
      {sortDisplay && !!topTwoRemainingItems.length && (
        <div aria-live="polite">
          <TopRemainingIconSvg className="asterix" />
          <Text className="top-remaining-help-text">
            These are the two largest accounts allocated to '{title}' and will be used by the ABS in statistical
            processing.{' '}
          </Text>
          <Text className="top-remaining-help-text-bottom">
            These help us understand what is impacting similar businesses this quarter.
          </Text>
        </div>
      )}
      {/* Main table display for mapped accounts */}
      <form onSubmit={handleSubmit(handleSaveManualItem)}>{ReportListTable}</form>
      <AddAccountsModal
        show={showAddItemModal}
        title={title}
        dspId={dspId}
        onClose={handleCloseModal}
        allocateAccounts={handleAllocateAccounts}
        dspItems={dspItems}
        dataItem={dataItem}
      />
    </Card>
  );
};

export default ReportList;
