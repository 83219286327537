import { LazyQueryResultTuple, OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import { GET_REPORT_DATA_INITIAL_LOAD } from 'apollo/queries/getReportDataInitialLoad';
import { evictGetReportFromCache } from 'apollo/states/utils/ClearReportStateData';
import { ChosenReport, GetReportResponse } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { recordRumError } from 'services/awsRum';

const useGetReport = (
  reportData: ChosenReport,
  preCache = false
):
  | LazyQueryResultTuple<GetReportResponse, OperationVariables>
  | [
      () => Promise<QueryResult<GetReportResponse, OperationVariables>>,
      QueryResult<GetReportResponse, OperationVariables>
    ] => {
  const [callGetReport, getReportQuery] = useLazyQuery<GetReportResponse>(GET_REPORT_DATA_INITIAL_LOAD, {
    // cache-first is default, included to make it obvious this will use the cached value if available
    fetchPolicy: 'cache-first',
    variables: reportData,
    onError: (error) => recordRumError(error),
  });

  // whether the consuming component has called the query
  const [consumerCalled, setConsumerCalled] = useState(false);
  // Async return value of callGetReport, to forward when the consuming component has called the query
  const [queryPromise, setQueryPromise] = useState<Promise<QueryResult<GetReportResponse, OperationVariables>>>();

  // Initial state to return until the consumer tries to call
  // Exhaustive deps ignored because we specifically don't want to pick up a new value
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultQueryObject: QueryResult<GetReportResponse, OperationVariables> = useMemo(() => getReportQuery, []);

  // Run query early if pre-cache is needed
  useEffect(() => {
    if (preCache) {
      setQueryPromise(callGetReport());
    }
  }, [preCache, callGetReport]);

  const loaded = getReportQuery.called && !getReportQuery.loading;
  const errored = loaded && (getReportQuery.error || !getReportQuery.data?.getReport.success);

  // Ensure failed responses will not be used when re-query is attempted
  useEffect(() => {
    if (errored) {
      evictGetReportFromCache(reportData);
      setQueryPromise(undefined);
    }
  }, [reportData, loaded, errored]);

  const wrappedCallGetReport = useCallback(() => {
    setConsumerCalled(true);

    if (typeof queryPromise !== 'undefined' && !errored) return queryPromise;

    // fallback in case query was not called yet
    const newQueryPromise = callGetReport();
    setQueryPromise(newQueryPromise);
    return newQueryPromise;
  }, [callGetReport, queryPromise, errored]);

  if (!preCache) return [callGetReport, getReportQuery];

  const queryObject = consumerCalled ? getReportQuery : { ...defaultQueryObject, loading: getReportQuery.loading };
  return [wrappedCallGetReport, queryObject];
};

export default useGetReport;
