/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Card, Flex, Heading, Image, Text } from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import ReportButtonGroup from 'components/ReportComponents/ReportButtonGroup/ReportButtonGroup';
import { userContactDetails } from 'apollo/states/UserContactDetails';
import { useLogOut } from 'utils/useLogOut';
import { chosenReport } from 'apollo/states/ChosenReport';
import Feedback from 'components/Feedback/Feedback';
import { currentReportData } from 'apollo/states/CurrentReportData';
import { PDFButton } from 'utils/PDFButton';
import { ReactComponent as SuccessIconSvg } from 'assets/icon-success.svg';
import ABSLogo from 'assets/logo-ABS.svg';
import './Confirmation.css';
import { DAYNUM_MONTHNAME_YEAR } from 'utils/dayjsFormats';
import InsightsWidget from 'components/InsightsWidget/InsightsWidget';

const Confirmation = () => {
  const { oktaAuth } = useOktaAuth();
  const user = userContactDetails();
  const { reportPeriod, organisationName } = chosenReport();
  const focusRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (focusRef.current != null) {
      focusRef.current.focus();
    }
  }, []);

  const { dataItems } = currentReportData();
  const feedbackDataItem = dataItems.find((item) => item.topic === 'ASPFeedback' && item.action === 'STAR');

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    chosenReport({
      ...chosenReport(),
      submitted: true,
      reportLastEditedDate: (dayjs().unix() * 1000).toString(),
    });
  }, []);

  return (
    <Flex className="confirmation-wrapper" direction="column">
      <Flex alignItems="center">
        <SuccessIconSvg className="confirmation-tick-icon" data-testid="tick-icon" height="30px" width="30px" />
        <Heading
          level={1}
          ref={focusRef}
          className="confirmation-heading"
          testId="confirmation-heading"
          role="status"
          aria-live="assertive"
          marginLeft="12px"
        >
          Your report has been submitted
        </Heading>
      </Flex>
      <Text className="submission-text" testId="confirmation-text" maxWidth="880px" marginTop="36px">
        Thank you for completing your ABS business report. A record of your submission is below.
      </Text>
      {feedbackDataItem && <Feedback feedbackDataItem={feedbackDataItem} pdfButtonRef={buttonRef} />}
      <Card className="submission-receipt-card" variation="outlined">
        <Flex alignItems="center">
          <Image testId="submission-logo" src={ABSLogo} alt="Australian Bureau of Statistics Logo" height="90px" />
          <Heading level={2} className="submission-logo-heading" testId="submission-logo-heading">
            Australian Bureau of Statistics
          </Heading>
        </Flex>
        <Flex direction="column" alignItems="flex-end">
          <PDFButton submitted={true} buttonRef={buttonRef} />
        </Flex>
        <Flex className="submission-section" direction="column">
          <SubmissionHeader title="Submission details" />
          <SubmissionInfo title="Business name" value={organisationName || ''} />
          <SubmissionInfo title="Quarter" value={`${reportPeriod || ''} report`} />
          <SubmissionInfo title="Submitted by" value={`${user.firstName} ${user.lastName}`} />
          <SubmissionInfo title="Submitted on" value={dayjs().format(DAYNUM_MONTHNAME_YEAR)} />
        </Flex>
        <Flex className="submission-section" direction="column">
          <SubmissionHeader title="Contact information" />
          <SubmissionInfo title="Email" value={user.email} secondary />
        </Flex>
      </Card>
      <InsightsWidget />
      <ReportButtonGroup
        nextButton={{ text: 'Back to dashboard', link: '/dashboard' }}
        backButton={{
          text: 'Log out',
          link: '/',
          Callback: () => {
            useLogOut(oktaAuth);
          },
        }}
      />
    </Flex>
  );
};

interface SubmissionHeaderProps {
  title: string;
}
const SubmissionHeader = (props: SubmissionHeaderProps) => {
  const { title } = props;
  return (
    <Heading level={3} className="submission-info-header" testId="submission-heading">
      {title}
    </Heading>
  );
};

interface SubmissionInfoProps {
  title: string;
  value: string;
  secondary?: boolean;
}
const SubmissionInfo = (props: SubmissionInfoProps) => {
  const { title, value, secondary = false } = props;
  return (
    <Flex justifyContent="space-between">
      <Text className="submission-text" testId="submission-detail-title">
        {title}
      </Text>
      <Text className={secondary ? 'submission-text' : 'submission-info-value'} testId="submission-detail-value">
        {value}
      </Text>
    </Flex>
  );
};

export default Confirmation;
