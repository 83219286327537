import {
  DataItem,
  DspDataItemObject,
  SavedActionListNumeric,
  SavedActionListText,
  SavedActionNumber,
  SavedActionText,
  SavedReportDataItem,
  SavedReportDataItems,
  ReportDataItemObject,
  SavedActionListNumericItem,
} from 'models';

const getDataItem = (dataItems: DataItem[], reportDataItemId?: string) =>
  dataItems.find((dataItem) => reportDataItemId === dataItem.dataItemId);

const getDspDataItem = (dspDataItems?: DspDataItemObject[], responseUid?: string) =>
  dspDataItems?.find((dspDataItem) => responseUid === dspDataItem.responseUid);

// Get the format to save the items based on the action provided by the report and return the typed local save state
const getSavedActionItem = (
  reportDataItem: ReportDataItemObject,
  dataItem?: DataItem,
  dspDataItems?: DspDataItemObject[],
  getDspUpdate?: boolean
): SavedActionListNumeric | SavedActionListText | SavedActionText | SavedActionNumber => {
  if (
    (dataItem?.action.includes('LIST') && !dataItem?.action.includes('TEXT')) ||
    dataItem?.action.includes('SORTDISPLAY')
  ) {
    const savedActionListNumeric: SavedActionListNumeric = {
      selectedReportDataItems: [],
      dataItemId: dataItem.dataItemId,
      dataItemText: dataItem.dataItemText,
      action: dataItem.action,
    };

    reportDataItem.items.forEach((item) => {
      const associatedDspDataItem = getDspDataItem(dspDataItems, item.responseUid);

      // Check if item is a dspItem. If it no longer exists in the dspDataItems list (e.g it has been removed/archived in DSP),
      //  then omit it from the saved answers array
      if (getDspUpdate && !item.userValue && !associatedDspDataItem) return;

      // Check for updates to account value or name
      const value = getDspUpdate ? associatedDspDataItem?.dspValue : item.dspValue;
      const accountName = getDspUpdate ? associatedDspDataItem?.accountName : item.accountName;
      const accountCode = getDspUpdate ? associatedDspDataItem?.code : item.accountCode;

      const savedActionListNumericItem: SavedActionListNumericItem = {
        accountName: associatedDspDataItem ? accountName : item.accountName,
        class: associatedDspDataItem ? associatedDspDataItem.class : item.class,
        dspDateRead: associatedDspDataItem ? associatedDspDataItem.dspDateSave : item.dspDateRead,
        dspValue: associatedDspDataItem ? value : undefined,
        mapState: !!associatedDspDataItem,
        responseUid: item.responseUid,
        isActive: item.isActive,
        type: item.type,
        userValue: !associatedDspDataItem ? item.userValue : undefined,
        code: associatedDspDataItem ? accountCode : undefined,
      };

      savedActionListNumeric.selectedReportDataItems.push(savedActionListNumericItem);
    });

    return savedActionListNumeric;
  }

  if (dataItem?.action.includes('NUMBER') || dataItem?.action.includes('STAR')) {
    let savedActionNumber: SavedActionNumber = { responseUid: '', savedNumber: 0 };

    if (reportDataItem.items.length > 0) {
      savedActionNumber = {
        responseUid: reportDataItem.items[0].responseUid,
        savedNumber: parseInt(reportDataItem.items[0].userValue || '0') || 0,
      };
    } else {
      savedActionNumber = {
        responseUid: `ASP-${crypto.randomUUID()}`,
        savedNumber: 0,
      };
    }

    return savedActionNumber;
  }

  let savedActionText: SavedActionText = { responseUid: '', savedText: '' };

  if (reportDataItem.items.length > 0) {
    savedActionText = {
      responseUid: reportDataItem.items[0].responseUid,
      savedText: reportDataItem.items[0].userValue || '',
    };
  } else {
    savedActionText = {
      responseUid: `ASP-${crypto.randomUUID()}`,
      savedText: '',
    };
  }

  return savedActionText;
};

export const mapGraphQLToLocal = (
  reportDataItems: ReportDataItemObject[],
  dataItems: DataItem[],
  dspDataItems: DspDataItemObject[],
  getDspUpdate = false
): SavedReportDataItems => {
  const savedReportDataItems: SavedReportDataItems = {
    savedAnswers: [],
  };

  // Iterate through the loaded report to map to local save state in Apollo.
  reportDataItems.forEach((reportDataItem) => {
    // Gather the essential items from the loaded Report
    if (reportDataItem.items.length > 0) {
      const associatedDataItem = getDataItem(dataItems, reportDataItem.dataItemId);
      const isCalculated = !associatedDataItem?.action.includes('_X');
      const savedAction = getSavedActionItem(reportDataItem, associatedDataItem, dspDataItems, getDspUpdate);

      const savedReportDataItem: SavedReportDataItem = {
        accountClass: associatedDataItem?.accountClass || '',
        action: associatedDataItem?.action,
        dataItemId: reportDataItem.dataItemId || '',
        isCalculated,
        savedReportDataItem: savedAction,
        topic: associatedDataItem?.topic,
      };

      savedReportDataItems.savedAnswers.push(savedReportDataItem);
    }
  });

  return savedReportDataItems;
};
