export enum RumCustomEvent {
  timeoutModalAppear = 'timeout_modal_appear',
  timeoutModalDismiss = 'timeout_modal_dismiss',
  timeout = 'timeout',
  reportOpen = 'report_open',
  reportClose = 'report_close',
  reportSubmit = 'report_submit',
  reportGetRetry = 'report_get_retry',
  logout = 'logout',
  windowClose = 'window_close',
  businessLoad = 'business_load',
  allocateModalSelectAll = 'allocate_modal_select_all',
  allocateModalDeselectAll = 'allocate_modal_deselect_all',
  expandAllSections = 'expand_all_sections',
  collapseAllSections = 'collapse_all_sections',
  expandIndividualSection = 'expand_individual_section',
  valueBackClickSuccess = 'value_back_click_success',
  valueBackClickError = 'value_back_click_error',
  downloadPdfClicked = 'download_pdf_clicked',
  contactUsClicked = 'contact_us_clicked',
  privacyAtAbsClicked = 'privacy_at_abs_clicked',
  dataReportingUsageClicked = 'data_reporting_usage_clicked',
  infoTooltipClicked = 'info_tooltip_clicked',
  navigateToIndustryInsights = 'navigate_to_industry_insights_clicked',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RumCustomEvent {
  export const allocateModalOpen = (dataItem: string): string => `allocate_modal_open_${dataItem}`;

  export const allocateModalClose = (dataItem: string): string => `allocate_modal_close_${dataItem}`;

  export const allocateModalAddAccounts = (dataItem: string): string => `allocate_modal_add_accounts_${dataItem}`;

  export const removeAllocatedDspAccount = (dataItem: string): string => `remove_allocated_dsp_account_${dataItem}`;

  export const removeManualEntryAccount = (dataItem: string): string => `remove_manual_entry_account_${dataItem}`;
}
