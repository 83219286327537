/* Standard formats for the dayjs format function, for readability
 * without reference to docs.
 *
 * Relevant docs are at https://day.js.org/docs/en/display/format
 *
 * Conventions:
 *
 *  - DAYNUM: day number (*without* padding leading zeros)
 *  - DAYNUMPADDED: day number padded to 2 digits with leading zeros
 *  - MONTHNAME: Whole month name without truncation
 *  - YEAR: 4 digit year
 */

export const DAYNUM_MONTHNAME_YEAR = 'D MMMM YYYY' as const;

export const MONTHNAME = 'MMMM' as const;

export const DAYNUM_MONTHNAME = 'D MMMM' as const;

export const YEAR = 'YYYY' as const;

export const MONTHNAME_YEAR = 'MMMM YYYY' as const;
