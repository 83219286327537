import {
  DefaultContext,
  MutationFunctionOptions,
  MutationTuple,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { useEffect } from 'react';
import { recordRumError } from 'services/awsRum';
import { SAVE_REPORT } from 'apollo/mutations/saveReport';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { resetLastSavedTimer } from 'apollo/states/SaveTimer';
import { savingLocalAnswersStatus, savingLocalAnswersToServer } from 'apollo/states/operationsInProgress';
import { SaveReportResponse, SavedReportDataItem } from 'models';
import { saveIntervalSeconds } from './saveInterval';

const useSaveReportWithResponse = (): MutationTuple<SaveReportResponse, OperationVariables> => {
  const [callSaveReport, saveReportResponse] = useMutation<SaveReportResponse>(SAVE_REPORT, {
    onCompleted: (
      response: SaveReportResponse,
      clientOptions?: MutationFunctionOptions<SaveReportResponse, OperationVariables, DefaultContext>
    ) => {
      if (response.saveReport.success) {
        savingLocalAnswersStatus('saved');
        const savedAnswers = (clientOptions?.context?.savedAnswers as SavedReportDataItem[]) ?? [];
        latestSavedReportDataItems({ savedAnswers });
        if (clientOptions?.context?.partialSave) {
          // If there is more to save, ensure next save starts soon
          resetLastSavedTimer(saveIntervalSeconds - 1);
        } else {
          resetLastSavedTimer();
        }
      } else {
        savingLocalAnswersStatus('error');
      }
    },
    onError: (error) => {
      recordRumError(error);
      savingLocalAnswersStatus('error');
    },
  });

  const { called, loading } = saveReportResponse;
  useEffect(() => {
    // Do not want to interfere with the save statuses unless this save is active
    if (!called) return;

    // loading means callSaveReport has been called and is in progress (not completed)
    if (loading) {
      savingLocalAnswersStatus('saving');
      savingLocalAnswersToServer(true);
    } else {
      savingLocalAnswersToServer(false);
      // savingLocalAnswersStatus is not set here because it will be set in onCompleted or onError
    }
  }, [called, loading]);

  return [callSaveReport, saveReportResponse];
};

export default useSaveReportWithResponse;
