/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState } from 'react';
import { Button, Card, Flex, Heading, Text, View, VisuallyHidden } from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useNavigate } from 'react-router-dom';
import 'utils/animation.css';

import { chosenReport } from 'apollo/states/ChosenReport';
import ReportProgressCapsule from 'components/ReportProgressCapsule/ReportProgressCapsule';
import ReportProgressText from 'components/ReportProgressText/ReportProgressText';
import { ReportStatus } from 'enums/ReportStatus';
import { ReportPeriodFormat } from 'utils/ReportPeriodFormat';
import { ChosenReport, Contact, UserContactDetails } from 'models';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { mapGraphQLToLocal } from 'utils/mapGraphQLToLocal';
import { mapGraphQLTopicsToLocal } from 'utils/mapGraphQLTopicsToLocal';
import { currentReportData } from 'apollo/states/CurrentReportData';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { useUserInfo } from 'lib/userInfoHook';
import { userContactDetails } from 'apollo/states/UserContactDetails';
import { saveTimer } from 'apollo/states/SaveTimer';
import { cleanseDspDataItems } from 'utils/cleanseDspDataItems';
import useSetContactDetails from 'utils/useSetContactDetails';
import handleGeneratePdf from 'utils/handleGeneratePDF';
import { recordRumCustomEvent } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import useDspApiErrorToast from 'hooks/useDspApiErrorToast';
import './ReportSummary.css';
import { LockState } from 'models/GraphQL/LockState';
import { DAYNUM_MONTHNAME_YEAR } from 'utils/dayjsFormats';
import { buildSubstitutePlaceholders } from 'utils/substitutePlaceholders';
import { useSetReportLock } from 'utils/useSetReportLock';
import { dashboardReportTabState, syncingDspBusinessNamesState } from 'apollo/states/operationsInProgress';
import { useReactiveVar } from '@apollo/client';
import useGetReport from 'utils/useGetReport';
import { isCurrentStatus, isPreviousStatus } from 'components/Dashboard/Dashboard';
import { withTimeout } from 'utils/withTimeout';
import { InlineError } from 'components/InlineError/InlineError';
import { GetReportTimerLabel } from './GetReportTimerLabel';

dayjs.extend(advancedFormat);
interface ReportSummaryProps {
  name: string;
  dueDate: string;
  lastEditedDate: string;
  status: ReportStatus;
  cycleStartDate: string;
  cycleEndDate: string;
  reportData: ChosenReport;
  preCache?: boolean;
  lockState?: LockState;
}

const ReportSummary = (props: ReportSummaryProps) => {
  const dashboardReportTab = useReactiveVar(dashboardReportTabState);
  const isSyncingDspBusinessNames = useReactiveVar(syncingDspBusinessNamesState);
  const { name, status, dueDate, lastEditedDate, cycleStartDate, cycleEndDate, reportData, lockState, preCache } =
    props;

  const navigate = useNavigate();
  const userInfo = useUserInfo();

  const [toastToShow, showToastOpenIfDSPError, clearDspApiToast] = useDspApiErrorToast();

  const dueDateNumber = parseInt(dueDate);
  const lastEditedDateNumber = parseInt(lastEditedDate);

  const dueDateObj = dayjs(dueDateNumber);

  const [callGetReport, getReportQuery] = useGetReport(reportData, preCache);

  // tracks thrown getReport errors
  const [inlineError, setInlineError] = useState(false);

  const [timedOut, setTimedOut] = useState(false);

  const [callSetContactDetail] = useSetContactDetails();

  const reportIsLocked = lockState?.lock && !lockState.selfLock;

  const lockReport = useSetReportLock('lock', { ...reportData });

  const isLoading = isSyncingDspBusinessNames || getReportQuery.loading;

  const handleLaunchOrReviewOrPdf = () => {
    setInlineError(false);
    setTimedOut(false);
    clearDspApiToast();
    withTimeout(callGetReport(), 60000)
      .then((response) => {
        const errorMessages = response.data?.getReport.errorMessages ?? [];

        if (!response.data || response.data?.getReport.success === false || errorMessages?.length > 0) {
          if (!showToastOpenIfDSPError(errorMessages)) {
            setInlineError(true);
          }
          return;
        }

        const generatingPdf = status === ReportStatus.Submitted || status === ReportStatus.SubmittedFeedback;
        const fetchedReport = response.data.getReport.report;
        const {
          reportStatus,
          dspDataItems: rawDspDataItems,
          topicAndDataItems,
          reportDataItems,
          lastMapState,
        } = fetchedReport;
        const dspDataItems = rawDspDataItems ? cleanseDspDataItems(rawDspDataItems) : [];
        const { dataItems: rawDataItems, topics } = topicAndDataItems[0];

        // Perform substitutions in question text
        const substitutePlaceholders = buildSubstitutePlaceholders(reportData);
        const dataItems = rawDataItems.map((dataItem) => {
          const dataItemText = substitutePlaceholders(dataItem.dataItemText);
          if (dataItemText === dataItem.dataItemText) return dataItem;

          return { ...dataItem, dataItemText };
        });

        const returningUser = reportStatus === ReportStatus.New && lastMapState?.length > 0;
        // Note: de-duplicated from pdf and non-pdf flow. pdf flow did not specify returningUser,
        // but returningUser is not read in pdf components so should not have any impact.
        const dashboardChosenReport = chosenReport({ ...reportData, returningUser });

        // On first load of report for returning users, use answers from their previously submitted report (lastMapState)
        // as the basis for this report's answers (savedReportDataItems.).
        const savedDataItems =
          generatingPdf || !returningUser
            ? reportDataItems
            : fetchedReport.lastMapState
                .map((item) => ({
                  dataItemId: item.dataItemId,
                  items: item.accountUid.map((uid) => ({ responseUid: uid })),
                }))
                .filter((item) => dataItems.find((dataItem) => dataItem.dataItemId === item.dataItemId));
        const dashboardPdfAnswers = savedReportDataItems(
          mapGraphQLToLocal(savedDataItems, dataItems, dspDataItems, true)
        );

        // Handle contact details setup for new users
        // Check contact details in response. If null:  update with okta, otherwise: set to local
        let baseContactDetails: Partial<Contact> = fetchedReport.contacts[0];

        if (!baseContactDetails) {
          // Pega only accepts 32 chars for name fields
          const firstName = userInfo?.firstName?.substring(0, 32);
          const lastName = userInfo?.lastName?.substring(0, 32);
          baseContactDetails = { firstName, lastName, email: userInfo?.email, phone: userInfo?.mobile, role: '' };
          if (!generatingPdf) {
            callSetContactDetail({
              variables: {
                saveContactDataObject: {
                  userReportId: reportData.userReportId,
                  dspProvider: reportData.dspProvider,
                  organisationId: reportData.organisationId,
                  contactDataObject: baseContactDetails,
                },
              },
            });
          }
        }
        const dashboardContacts = toUserContactDetails(baseContactDetails, '');

        if (!generatingPdf) {
          userContactDetails(dashboardContacts);
        }

        // Name needs improving, but consistent with other 'dashboard' variable names
        const dashboardTopics = mapGraphQLTopicsToLocal(topics, dataItems, reportData.isUsingDsp);

        if (generatingPdf) {
          recordRumCustomEvent(RumCustomEvent.downloadPdfClicked, {
            pageId: window.location.pathname,
            ...getRumAttributes(),
          });
          // This is almost identical to else block when contact details is defined. Combine?
          handleGeneratePdf(true, {
            dashboardContacts,
            topics: dashboardTopics,
            dashboardPdfAnswers,
            dashboardPdfQuestions: dataItems,
            dashboardChosenReport: {
              ...dashboardChosenReport,
              // dspAbn: 'fakeAbn',
              // dspDisplayName: 'ABS BUSINESS 18',
              // dspProvider: 'XERO',
            },
          });
        } else {
          // Non-pdf workflow

          // Not used in PDF workflow:
          // Populate the record of the answers saved on the server
          latestSavedReportDataItems(mapGraphQLToLocal(reportDataItems, dataItems, dspDataItems));
          currentReportData({ dataItems, dspDataItems, topics: dashboardTopics });
          saveTimer({ timer: 0 });
          recordRumCustomEvent(RumCustomEvent.reportOpen, getRumAttributes());
          lockReport();
          if (returningUser || fetchedReport.reportStatus === ReportStatus.Edit) {
            navigate('/report/review');
          } else {
            navigate('/report/instructions');
          }
        }
      })
      .catch((error) => {
        setInlineError(true);

        if (error?.message === 'Promise timed out') {
          setTimedOut(true);
        }
      });
  };

  const getReportError = !!(
    !getReportQuery ||
    getReportQuery.error ||
    getReportQuery.data?.getReport?.success === false
  );
  const retryable = !isLoading && (getReportError || inlineError);

  const renderButtonText = () => {
    if (retryable) return 'Try again';
    if (reportIsLocked) return 'Report in use';

    // In practice this is provided by the loading state of the button
    if (isLoading) return 'Loading report';

    if (status === 'new' || status === 'edit') return 'Launch report';
    return 'Download PDF';
  };

  const buttonText = renderButtonText();

  // if the report's tab is not selected, return null
  if (
    (isCurrentStatus(status) && dashboardReportTab === 'past') ||
    (isPreviousStatus(status) && dashboardReportTab === 'current')
  ) {
    return null;
  }

  const launchDisabled = reportIsLocked || status === ReportStatus.UnderReview;

  return (
    <Card variation="elevated" className="report-card" testId="report-card-container">
      <Flex className="report-top-info" direction="row" wrap="wrap">
        <Flex direction="column" wrap="wrap">
          <Heading level={3} className="report-heading" testId="report-heading">
            {name}
          </Heading>
          <Text className="report-text" testId="report-period">
            {ReportPeriodFormat(cycleStartDate, cycleEndDate)}
          </Text>
        </Flex>
        <Flex direction="column" marginLeft="auto" textAlign="right" wrap="wrap" paddingRight={14}>
          <Heading level={4} className="report-date-heading" testId="report-due-heading">
            Report due
          </Heading>
          <Text className="report-date" testId="report-date">
            {dueDateObj.format(DAYNUM_MONTHNAME_YEAR)}
          </Text>
        </Flex>
      </Flex>
      <Flex className="report-bottom-info" alignItems="start" justifyContent="space-between" wrap="wrap">
        <div className="progress-container">
          <ReportProgressCapsule status={status} dueDate={dueDateNumber} />
          <View className="progress-text-wrapper">
            <View marginLeft="auto" textAlign="left" testId="report-status-details">
              <ReportProgressText status={status} dueDate={dueDateNumber} dateSubmitted={lastEditedDateNumber} />
            </View>
          </View>
        </div>
        {[
          ReportStatus.New,
          ReportStatus.Edit,
          ReportStatus.UnderReview,
          ReportStatus.Submitted,
          ReportStatus.SubmittedFeedback,
        ].includes(status) && (
          <Flex direction="column" justifyContent="end">
            <Button
              className="report-action-btn"
              testId="report-action-btn"
              variation="primary"
              disabled={launchDisabled}
              aria-disabled={launchDisabled}
              onClick={() => {
                if (retryable) {
                  recordRumCustomEvent(
                    RumCustomEvent.reportGetRetry,
                    getRumAttributes({ providerId: reportData.dspProvider, reportId: reportData.userReportId })
                  );
                }
                handleLaunchOrReviewOrPdf();
              }}
              // Using the below instead of 'isLoading={getReportQuery.loading}' because
              //   Amplify Button doesnt apply disabled class if loading prop is present
              {...(isLoading && { isLoading: true })}
              loadingText="Loading report"
            >
              {buttonText}
              <VisuallyHidden> for {name}</VisuallyHidden>
            </Button>
            {getReportQuery.loading && <GetReportTimerLabel />}
            {getReportError && (!isLoading || timedOut) && (
              <div className="long-text fade-in">
                <InlineError errorMessage="We couldn't contact your accounting software. Please try again later." />
              </div>
            )}
          </Flex>
        )}
      </Flex>
      {toastToShow}
    </Card>
  );
};

function toUserContactDetails(
  contact: Partial<Contact> | undefined,
  defaultEmail = 'user@email.com'
): UserContactDetails {
  return {
    firstName: contact?.firstName ?? '',
    lastName: contact?.lastName ?? '',
    email: contact?.email ?? defaultEmail,
    mobile: contact?.phone ?? '',
    role: contact?.role ?? '',
  };
}

export default ReportSummary;
