import { externalLinks } from 'lib/externalLinks';

export const DSPwelcomepageprop = {
  list: [
    'If the business you are reporting for uses Xero, cloud-based MYOB or QuickBooks Online, you can  choose to securely connect to your accounting software to help you report.',
    'Your information in ABS Business Reporting is collected under the Census and Statistics Act 1905. This means we are legally required to protect your data and maintain its confidentiality.',
  ],
  uniqueId: 'welcome',
};

export const DSPabsusedatapageprop = {
  style: 'card',
  heading: 'Why connect to accounting software?',
  list: [
    'When you connect to your accounting software, you will be able to see information about your selected business and select accounts to help complete your report',
    'Your selections will be remembered to make completing future reports quicker and easier',
    'We will not disclose the information you provide for compliance or regulatory purposes',
    'Information in accounting software is only accessed for the purpose of report completion and the report submitted by you is the only version of the data we will use',
    'We will not access personal information like employee details or tax file numbers (TFNs)',
    'You can revoke ABS access to your accounting software at any time',
  ],
  linkContact: externalLinks.absContactUs,
  footer: {
    text: 'If you want to know more about how we use your data visit our Data Privacy and Security and ABS Business Reporting Data Usage pages',
    link: {
      url: externalLinks.absDataPrivacySecurity,
      text: 'Data Privacy and Security',
    },
    linkTwo: {
      url: externalLinks.absBusinessReportingDataUsage,
      text: 'ABS Business Reporting Data Usage',
    },
  },
  uniqueId: 'usage',
};
