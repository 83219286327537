/**
 * These correlate to error codes in the back-end project, currently defined
 * in asp-deploy-repository-backend\lambdas\common\error-messages.ts
 */
export enum ApiErrorMsg {
  internalSystemError = 3,
  invalidClaim = 9,
  noAbnFoundForClaim = 10,
  alreadyClaimed = 11,

  requestFailed401 = 24,

  dspApiNoAbnError = 21,
  dspApiErrorCode = 25,
  dspApiTimeoutCode = 26,
  dspApiLambaTimeout = 27,
}

export const dspApiErrorCodes = [
  ApiErrorMsg.dspApiErrorCode,
  ApiErrorMsg.dspApiTimeoutCode,
  ApiErrorMsg.dspApiLambaTimeout,
];
