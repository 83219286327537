import { gql } from '@apollo/client';

export const GET_AUTHORISED_BUSINESSES = gql`
  query GetAuthorisedBusinesses {
    getUserProfile {
      success
      errorMessages {
        errorCode
        language
        message
        metaData
      }
      user {
        accessDetails {
          dsp
        }
      }
    }
  }
`;
