import { gql } from '@apollo/client';

export const SET_LAST_ACTIVE_BUSINESS = gql`
  mutation setLastActiveBusiness($dspProvider: String!, $organisationId: String!) {
    setLastActiveBusiness(dspProvider: $dspProvider, organisationId: $organisationId) {
      success
      errorMessages {
        message
        errorCode
        language
        metaData
      }
    }
  }
`;
