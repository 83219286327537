import { OktaAuth } from '@okta/okta-auth-js';
import { recordRumCustomEvent } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from './getRumAttributes';

/* istanbul ignore file */
export const useLogOut = (auth: OktaAuth, state = '') => {
  recordRumCustomEvent(RumCustomEvent.logout, getRumAttributes());
  sessionStorage.clear();
  auth.signOut({ postLogoutRedirectUri: `${window.location.origin}`, state });
};
