import './ReportQuestion.scss';
import { useState } from 'react';
import { Card, Text, TextAreaField } from '@aws-amplify/ui-react';
import { useReactiveVar } from '@apollo/client';
import 'index.css';
import { TooltipComponent } from 'components/TooltipComponent/TooltipComponent';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { chosenReport } from 'apollo/states/ChosenReport';
import { DataItem, SavedActionText, SavedReportDataItem } from 'models';
import { allAllowedCharsRegex } from 'utils/CharacterValidation';

import { ReactComponent as IconInfoWarnSvg } from 'assets/icon-info-warn.svg';

interface Props {
  title?: string;
  text: string;
  helpTexts?: string[];
  mandatory?: boolean;
  length?: number;
  dataItem: DataItem;
  minified?: boolean;
}
export const ReportQuestion: React.FC<Props> = ({ title, text, helpTexts, mandatory, length, dataItem, minified }) => {
  const [requiredError, setRequiredError] = useState<boolean>(false);
  const [validateError, setValidateError] = useState<boolean>(false);
  const [inputFocused, setInputFocused] = useState(false);
  const chosenReportDetails = chosenReport();

  const savedValues = useReactiveVar(savedReportDataItems);
  const serverValues = useReactiveVar(latestSavedReportDataItems);
  const savedValue: SavedReportDataItem | undefined = savedValues.savedAnswers.find(
    (x) => x.dataItemId === dataItem.dataItemId
  );
  const savedInServer = serverValues.savedAnswers.find((x) => x.dataItemId === dataItem.dataItemId);

  const generateCountdownText = (value: string, limit: number | undefined): { text: string; withinLimit: boolean } => {
    if (limit) {
      const charactersRemaining = limit - value.length;
      return {
        text: `${charactersRemaining < 0 ? '0' : charactersRemaining} ${
          charactersRemaining === 1 ? 'character' : 'characters'
        } remaining`,
        withinLimit: charactersRemaining < 0,
      };
    }
    return { text: '', withinLimit: false };
  };

  const initialCountdownText = generateCountdownText(
    (savedValue?.savedReportDataItem as SavedActionText)?.savedText ?? '',
    length
  );
  const [liveCountdownText, setLiveCountdownText] = useState<string>(initialCountdownText.text);
  const [isHighlighted, setIsHighlighted] = useState<boolean>(initialCountdownText.withinLimit);

  const validateEntry = (value: string): boolean => {
    if (mandatory && value === '') {
      setRequiredError(true);
      return false;
    }
    setRequiredError(false);
    if (!value.match(allAllowedCharsRegex)) {
      setValidateError(true);
      return false;
    }
    setValidateError(false);
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let { value } = e.target;

    if (length) {
      const countdownData = generateCountdownText(value, length);
      setLiveCountdownText(countdownData.text);
      setIsHighlighted(value.length > length);

      // cut off field at length limit
      value = value.length > length ? value.slice(0, length) : value;
    }

    const responseUid = savedValue
      ? (savedValue?.savedReportDataItem as SavedActionText).responseUid
      : `ASP-${crypto.randomUUID()}`;

    const savedActionText: SavedActionText = {
      savedText: value,
      responseUid,
    };

    if (savedValue === undefined) {
      // TODO: figure out if calculated or not
      const newReportDataItem: SavedReportDataItem = {
        accountClass: dataItem.accountClass || '',
        dataItemId: dataItem.dataItemId,
        isCalculated: false,
        savedReportDataItem: savedActionText,
        topic: dataItem.topic,
        overwrite: false,
        action: dataItem.action,
      };
      savedReportDataItems({
        savedAnswers: [...savedValues.savedAnswers, newReportDataItem],
      });
    } else if (value === '' && !savedInServer) {
      // remove the item from saved answers
      const excludedSavedReportDataItems = savedValues.savedAnswers.filter(
        (i: SavedReportDataItem) => i.dataItemId !== savedValue.dataItemId
      );
      savedReportDataItems({ savedAnswers: excludedSavedReportDataItems });
    } else {
      const updatedSavedReportDataItems = savedValues.savedAnswers.map((i: SavedReportDataItem) =>
        i.dataItemId === dataItem.dataItemId ? { ...i, savedReportDataItem: savedActionText } : i
      );

      savedReportDataItems({ savedAnswers: updatedSavedReportDataItems });
    }
  };

  const isNoDsp = chosenReportDetails.isUsingDsp === false;

  const showWarning =
    !isNoDsp &&
    chosenReportDetails.returningUser &&
    (savedValue?.savedReportDataItem as SavedActionText)?.savedText === undefined &&
    !inputFocused;

  return (
    <Card
      testId="question-component"
      className={`question-component ${minified && 'question-component-minified'}`}
      as="form"
      width={minified && !isNoDsp ? '80%' : '100%'}
    >
      {minified ? (
        <div style={{ marginBottom: '8px' }}>
          {text}
          {showWarning && (
            <IconInfoWarnSvg
              className="review-warning-icon"
              style={{ verticalAlign: 'text-top', marginLeft: '12px' }}
            />
          )}
        </div>
      ) : (
        <TooltipComponent text={text} helpTexts={helpTexts} dataItemId={dataItem.dataItemId} />
      )}
      {title && <Text className="question-title">{title}</Text>}
      {minified && isNoDsp ? (
        <Text color="#4B4F54" minHeight={80} marginTop={20}>
          {(savedValue?.savedReportDataItem as SavedActionText)?.savedText || 'No response provided'}
        </Text>
      ) : (
        <>
          <TextAreaField
            className={
              isHighlighted && inputFocused
                ? 'question-input-field report-input-field text-area-highlighted'
                : 'question-input-field report-input-field'
            }
            testId="question-input-field"
            descriptiveText={title || 'Comments'}
            isRequired={mandatory}
            hasError={requiredError || validateError}
            label={text ?? 'Type your comments here'}
            labelHidden
            aria-placeholder="Type your comments here"
            aria-describedby="text-limit"
            onChange={(e) => {
              if (validateEntry(e.currentTarget.value)) handleChange(e);
            }}
            value={(savedValue?.savedReportDataItem as SavedActionText)?.savedText || ''}
            onFocus={() => setInputFocused(true)}
            onBlur={() => {
              setInputFocused(false);
              setIsHighlighted(false);
            }}
          />
          {requiredError && (
            <p className="input-error-message" data-testid="input-error-required-message">
              Required
            </p>
          )}
          {validateError && (
            <p className="input-error-message" data-testid="input-error-validation-message">
              Only alphanumeric and standard special characters allowed
            </p>
          )}
          {length && (
            <Text
              testId="question-footer"
              id="text-limit-footer"
              className={
                isHighlighted && inputFocused ? 'question-limit-footer text-highlighted' : 'question-limit-footer'
              }
            >
              {isHighlighted && inputFocused ? 'Character limit reached' : liveCountdownText}
            </Text>
          )}
        </>
      )}
    </Card>
  );
};
