import { useReactiveVar } from '@apollo/client';
import { Flex } from '@aws-amplify/ui-react';
import { useSaveReport } from 'utils/useSaveReport';
import { useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { visitedReportPages } from 'apollo/Cache';
import './SideNavbar.scss';
import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { StepID } from 'apollo/states/utils/useCurrentReportSteps';

const getLinkStateClass = (current: boolean, visited: boolean) => {
  if (current) return 'processing';
  if (visited) return 'completed';
  return '';
};

const SideNavbarLinkItem = ({ step, title, to }: { step: StepID; title: string; to: string }) => {
  const saveReport = useSaveReport();

  const location = useLocation();
  const currentReportPage = location.pathname.split('/').pop();
  const current = step === currentReportPage;
  const wasVisited = useReactiveVar(visitedReportPages).includes(step);
  const visited = wasVisited || current;

  // save this as a visited page if it is the current page
  useEffect(() => {
    if (wasVisited || !current) return;

    const previousVisited = visitedReportPages();
    visitedReportPages([...previousVisited, step]);
  }, [step, current, wasVisited]);

  const stateClass = getLinkStateClass(current, visited);

  const handleOnClick = () => {
    saveReport();
  };

  return (
    <li className={`side-navBar-item ${stateClass}`}>
      <Link
        data-testid={`navbar-${step}`}
        className="navbar-link"
        aria-current={current && 'page'}
        to={to}
        onClick={() => handleOnClick()}
      >
        {title}
      </Link>
    </li>
  );
};

export const SideNavbar = () => {
  const allCurrentReportTopics = useCurrentReportTopics();
  const currentReportTopics = useMemo(
    () => allCurrentReportTopics.filter(({ isFeedback }) => !isFeedback),
    [allCurrentReportTopics]
  );

  return (
    <Flex
      testId="side-navbar"
      className="side-navbar-container"
      direction="column"
      justifyContent="flex-start"
      gap="0"
      alignContent="flex-start"
    >
      <ol className="side-navbar">
        <SideNavbarLinkItem step="instructions" title="Instructions" to="/report/instructions" />
        {currentReportTopics.map((topicDetail) => (
          <SideNavbarLinkItem
            key={topicDetail.key}
            step={topicDetail.key}
            title={topicDetail.topicShortTitle}
            to={topicDetail.path}
          />
        ))}
        <SideNavbarLinkItem step="review" title="Review" to="/report/review" />
      </ol>
    </Flex>
  );
};
