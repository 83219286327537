/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { useReactiveVar } from '@apollo/client';
import { Button, Flex, Heading, Text, View, Grid } from '@aws-amplify/ui-react';
import {
  Observations,
  SeriesValue,
  SelectOption,
  defaultIndustry,
  getBusinessInsights,
  ABSInsights,
  getKeyDimentionalSeriesKey,
  getInsightsValue,
  getSelectedIndustryIndex,
} from 'services/absInsightsAPI';
import { InsightsMeasures } from 'enums/InsightsMeasures';
import { recordRumCustomEvent } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import Graph from '../InsightMeasureDetails/Graph';

type SeriesObject = {
  name: string;
  data: Array<number>;
  color: string;
  showInLegend: false;
  type: string;
};

const InsightsWidget = () => {
  const [insightData, setInsightData] = useState<Map<string, Observations>>();
  const [industries, setIndustries] = useState<SeriesValue | null>(null);
  const [measures, setMeasures] = useState<SeriesValue | null>(null);
  const [dates, setDates] = useState<string[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<SelectOption>(defaultIndustry);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(false);
  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const { activeBusiness } = currentBusinesses;

  const navigate = useNavigate();

  const options: Highcharts.Options = {
    title: {
      text: '',
    },
    lang: {
      noData: `The ABS does not currently publish industry data for this division.`,
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030',
        textAlign: 'center !important',
        whiteSpace: 'normal !important',
      },
      useHTML: true,
    },
    chart: {
      type: 'column',
      borderRadius: 8,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: dates.slice(dates.length - 4), // hardcoded for the last 4 datapoints
      reversed: false,
      title: {
        text: '',
      },
    },
    yAxis: {
      title: {
        text: 'Percentage change',
      },
      labels: {
        format: '{value}%',
      },
      endOnTick: false,
    },
    tooltip: {
      valueSuffix: '%',
      shared: true,
    },
    accessibility: {
      description: 'This chart displays',
    },
  };

  useEffect(() => {
    const fetchDataForPosts = async () => {
      try {
        setLoading(true);
        setError(false);
        const data = (await getBusinessInsights()) as ABSInsights;
        // ABS Date
        const series = new Map(Object.entries(data.dataSets.find((set) => set.action === 'Information')!.series));
        setInsightData(series);
        // The measures representing the data and where to find them
        setMeasures(data.structure.dimensions.series.find((a) => a?.id === 'MEASURE')!);
        // The industries represented in the data and where to find them
        const divisions = data.structure.dimensions.series.find((a) => a?.id === 'INDUSTRY')!;
        setIndustries(divisions);
        // The dates of the data in the series
        const newDates: string[] = [];
        data.structure.dimensions.observation
          .find((a) => a?.id === 'TIME_PERIOD')!
          .values.map((time) => (time.end ? newDates.push(dayjs(time.end).format('MMM YYYY')) : false));
        setDates(newDates);
        // When the user has an active business with a valid div so it first
        if (divisions && activeBusiness?.division) {
          const matchedIndustry = divisions.values.find((div) => div.id === activeBusiness?.division);
          if (matchedIndustry?.id && matchedIndustry.name) {
            setSelectedIndustry({ label: matchedIndustry.name, value: matchedIndustry?.id });
          }
        }
        setLoading(false);
      } catch (err: any) {
        console.error(err.message);
        setError(true);
        setLoading(false);
      }
    };

    fetchDataForPosts();
  }, [activeBusiness, retry]);

  const getCurrentIndustry = () => {
    if (activeBusiness && industries?.values) {
      return industries?.values.find((i) => i.id === activeBusiness.division)?.name;
    }
    return 'Your';
  };

  const getSeriesData = () => {
    const newSeriesArray: Array<SeriesObject> = [];
    if (measures?.values) {
      measures.values.map((measure, i) => {
        const InsightsMeasureConfig = InsightsMeasures.find((m) => m.key === measure.id)!;
        if (industries) {
          const data = getInsightsValue(
            getKeyDimentionalSeriesKey(
              industries?.keyPosition,
              getSelectedIndustryIndex(selectedIndustry, industries),
              getKeyDimentionalSeriesKey(measures.keyPosition, String(i))
            ),
            insightData!
          );
          const series: SeriesObject = {
            type: 'column',
            showInLegend: false,
            data: data.slice(data.length - Number(4)),
            color: InsightsMeasureConfig.color,
            name: InsightsMeasureConfig.name,
          };

          newSeriesArray.push(series);
        }
        return false;
      });
    }
    return newSeriesArray;
  };

  const handleNavigate = () => {
    recordRumCustomEvent(RumCustomEvent.navigateToIndustryInsights);
    navigate('/business-indicators');
  };

  return (
    <Flex
      testId="industry-insights-widget-component"
      direction="column"
      alignItems="stretch"
      marginTop="15px"
      borderRadius="16px"
      style={{ background: '#FAFAFA', padding: '32px' }}
    >
      <Heading level={3}>{getCurrentIndustry()} industry insights</Heading>
      <Grid templateColumns="1fr 4fr">
        <Flex direction="column" justifyContent="space-between" style={{ width: '262px', padding: '1rem 1rem 0 0' }}>
          <Flex direction="column">
            <Text>
              <b>Stay informed:</b> Keep up to date with your industry’s trends and statistics.
            </Text>
            <Text>
              <b>See your impact:</b> View the statistics your report helps the ABS to create.
            </Text>
            <Text>
              <b>Understand the economy:</b> Learn what statistics say about the whole economy and how that might impact
              on businesses.
            </Text>
          </Flex>
          <Flex>
            <Button
              testId="report-action-btn"
              variation="primary"
              style={{ width: '-webkit-fill-available' }}
              onClick={() => handleNavigate()}
            >
              Explore industry insights
            </Button>
          </Flex>
        </Flex>
        <View style={{ paddingLeft: '1rem' }}>
          <Graph
            options={{ ...options, series: getSeriesData() }}
            onRetry={() => setRetry(!retry)}
            isLoading={isLoading}
            error={error}
            showRetry
          />
        </View>
      </Grid>
    </Flex>
  );
};

export default InsightsWidget;
