import { Text, View } from '@aws-amplify/ui-react';
import { FocusEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import Select, {
  components,
  MenuProps,
  GroupProps,
  OptionProps,
  StylesConfig,
  InputProps,
  SingleValue,
  InputActionMeta,
} from 'react-select';

import { AuthorisedBusiness } from 'models';
import BusinessSelectMenuItem from 'components/BusinessSelectMenuItem/BusinessSelectMenuItem';
import { businessSelectStyles, CustomDropdownIndicator, Control } from 'lib/BusinessSelectComponents';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { SET_LAST_ACTIVE_BUSINESS } from 'apollo/mutations/setLastActiveBusiness';
import { recordRumCustomEvent } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';

import './BusinessSelect.css';
import { useMutation, useReactiveVar } from '@apollo/client';
import { getRumAttributes } from 'utils/getRumAttributes';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

interface BusinessSelectOption {
  label: string;
  value: AuthorisedBusiness | string;
  disabled?: boolean;
}

interface BusinessGroup {
  label: string;
  options: BusinessSelectOption[];
}

const dashboardSelectStyles: StylesConfig<BusinessSelectOption, false> = {
  ...businessSelectStyles,
  menu: (styles) => ({
    ...styles,
    margin: 0,
    borderRadius: '8px',
    border: '1px solid #ebeced',
    borderTopWidth: 0,
    boxShadow: '0 4px 6px -2px #0000000f, 0 12px 16px -4px #0000001a',
  }),
  groupHeading: (styles) => ({
    ...styles,
    textTransform: 'initial',
  }),
  option: (base, selectProps) => ({
    ...base,
    padding: '14px 16px',
    backgroundColor: selectProps.isFocused ? '#deebff' : 'inherit',
  }),
  placeholder: (base) => ({
    ...base,
    display: 'none',
  }),
};

const BusinessSelect = () => {
  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const { activeBusiness, authorisedBusinesses: allAuthorisedBusinesses } = currentBusinesses;
  const [callSetActiveBusiness] = useMutation(SET_LAST_ACTIVE_BUSINESS);

  const businesses: BusinessSelectOption[] = useMemo(
    () =>
      allAuthorisedBusinesses.map((business) => ({
        label: `${business.name}, ${business.suburb}`,
        value: business,
      })),
    [allAuthorisedBusinesses]
  );

  const currentOption = useMemo(
    () =>
      businesses.find((business) =>
        typeof business.value !== 'string' ? business.value?.id === activeBusiness?.id : null
      ) ?? null,
    [activeBusiness?.id, businesses]
  );

  const otherBusinessOptions = useMemo(
    () =>
      businesses.filter((business) => {
        if (
          typeof business.value !== 'string' &&
          typeof currentOption?.value !== 'string' &&
          business.value.id !== currentOption?.value.id
        ) {
          return business;
        }
        return null;
      }),
    [businesses, currentOption?.value]
  );

  const groupedOptions: BusinessGroup[] = useMemo(
    () => [
      {
        label: 'Active business',
        options: [currentOption ?? ({} as BusinessSelectOption)],
      },
      {
        label: 'Authorised businesses',
        options: [
          ...(otherBusinessOptions ?? {}),
          { label: 'No other authorised businesses', value: 'noOptionsMessage', disabled: true },
        ],
      },
    ],
    [currentOption, otherBusinessOptions]
  );

  // State to store if no authorised businesses are visible and create list entry with no options message
  const [onlySelectedVisible, setOnlySelectedVisible] = useState(groupedOptions[1].options.length === 1);

  const [inputText, setInputText] = useState('');
  const [inputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    setOnlySelectedVisible(groupedOptions[1].options.length === 1);
  }, [groupedOptions]);

  const handleSelectChange = useCallback(
    (selectedOption: SingleValue<BusinessSelectOption>) => {
      if (!selectedOption) return;
      if (typeof selectedOption.value === 'string') return;

      const selectedId = selectedOption.value.id;
      const selectedOrganisation = allAuthorisedBusinesses.find(({ id }) => id === selectedId);

      if (activeBusiness?.id === selectedOrganisation?.id) return;

      setInputText('');

      recordRumCustomEvent(
        RumCustomEvent.businessLoad,
        getRumAttributes({ providerId: selectedOrganisation?.providerId })
      );
      if (selectedOrganisation)
        callSetActiveBusiness({
          variables: { dspProvider: selectedOrganisation.dsp, organisationId: selectedOrganisation.id },
        });
      authorisedBusinesses({
        activeBusiness: selectedOrganisation,
        authorisedBusinesses: allAuthorisedBusinesses,
      });
    },
    [activeBusiness, allAuthorisedBusinesses, callSetActiveBusiness]
  );

  const filterByLabel = (option: BusinessSelectOption, input: string) =>
    option.label.toLowerCase().includes(input.toLowerCase());

  // overwrite filter function to keep selected option visible at all times and filter out noOptionsMessage
  // unless onlySelectedVisible is true
  const filterOptions = (option: FilterOptionOption<BusinessSelectOption>, input: string) => {
    if (option.value === 'noOptionsMessage') return onlySelectedVisible;
    return !input || option.label === groupedOptions[0].options[0].label || filterByLabel(option, input);
  };

  // check if all non-selected items are being filtered out to display no options message
  const handleInputChange = (input: string, action: InputActionMeta) => {
    if (action.action === 'input-change') {
      setInputText(input);
    }
    setOnlySelectedVisible(!otherBusinessOptions.filter((business) => filterByLabel(business, input)).length);
  };

  // controlShouldRenderValue is disabled to allow placeholder to be shown even though a business is selected
  return (
    <View className="business-select-wrapper" testId="business-select-wrapper">
      {inputFocused && (
        <div className="select-label fade-in">
          <label className="amplify-label" htmlFor="business-switcher-id ">
            Search businesses
          </label>
        </div>
      )}
      <Select<BusinessSelectOption, false>
        isMulti={false}
        aria-label="Switch business"
        className="business-select"
        openMenuOnFocus
        controlShouldRenderValue={false}
        closeMenuOnSelect
        blurInputOnSelect
        styles={dashboardSelectStyles}
        value={currentOption}
        onFocus={() => setInputFocused(true)}
        onBlur={() => {
          setInputFocused(false);
          setInputText('');
        }}
        components={{
          Menu,
          Group,
          Input,
          Option: CustomOption,
          DropdownIndicator: CustomDropdownIndicator,
          Control,
        }}
        id="business-switcher-id"
        inputId="business-switcher-input"
        options={groupedOptions}
        onChange={handleSelectChange}
        inputValue={inputText}
        filterOption={filterOptions}
        onInputChange={handleInputChange}
        isOptionDisabled={(option) => !!option.disabled}
      />
    </View>
  );
};

// Menu component to contain the selected business and then list the remaining companies
const Menu = ({ children, ...props }: MenuProps<BusinessSelectOption, false>) => (
  <View className="business-select-menu-wrapper fade-in" testId="business-select-menu-wrapper">
    <components.Menu {...props} className="business-select-menu">
      {children}
    </components.Menu>
  </View>
);

// Input component to limit max length
const Input = ({ ...props }: InputProps<BusinessSelectOption>) => {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    if (props.onFocus) {
      props?.onFocus(event);
    }
    setIsFocused(true);
  };

  const onBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (props.onBlur) {
      props?.onBlur(event);
    }
    setIsFocused(false);
  };

  return (
    <components.Input
      {...props}
      onFocus={onFocus}
      onBlur={onBlur}
      maxLength={250}
      placeholder={isFocused ? '' : ' Switch business'}
      style={{ width: '100vw', border: 0, outline: 0 }}
    />
  );
};

// Group component to contain the options
const Group = ({ children, ...props }: GroupProps<BusinessSelectOption, false>) => (
  <View className="business-select-group" testId="business-select-group">
    <Text testId="menu-heading-authorised-businesses">{props.label}</Text>
    <span data-testid="business-select-menu-authorised-business">{children}</span>
  </View>
);

// Custom option that returns no options message when it is present
const CustomOption = ({ data, ...props }: OptionProps<BusinessSelectOption>) => {
  if (typeof data.value === 'string') {
    return (
      <View className="business-select-empty-list">
        <Text>{data.label}</Text>
      </View>
    );
  }
  return (
    <components.Option {...props} data={data}>
      <BusinessSelectMenuItem business={data.value} />
    </components.Option>
  );
};

export default BusinessSelect;
