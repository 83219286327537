import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DAYNUM_MONTHNAME, MONTHNAME, MONTHNAME_YEAR, YEAR } from './dayjsFormats';

dayjs.extend(utc);

export const ReportPeriodFormat = (startString: string, endString: string): string => {
  let periodText = '';
  const start = dayjs.utc(Number(startString));
  const end = dayjs.utc(Number(endString));

  if (start.isValid() && end.isValid()) {
    switch (Math.round(end.diff(start, 'month', true))) {
      case 3:
        periodText = `${end.format(MONTHNAME)} quarter`;
        break;
      case 12:
        periodText = `${end.format(YEAR)} year`;
        break;
      default:
        periodText = 'Reporting period';
    }
    return `${periodText} (${start.format(DAYNUM_MONTHNAME)} - ${end.format(DAYNUM_MONTHNAME)}) ${end.format(YEAR)}`;
  }

  return periodText;
};

export const ReportEndFormat = (endString: string): string => {
  const end = dayjs.utc(Number(endString));
  if (end.isValid()) {
    return end.format(MONTHNAME_YEAR);
  }

  return '';
};
