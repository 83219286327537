/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { useReactiveVar } from '@apollo/client';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import dayjs from 'dayjs';
import { Heading, Text, Flex, Link } from '@aws-amplify/ui-react';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import ValueBackCard from 'components/ValueBack/ValueBackCard';
import InsightsSelect from 'components/InsightsSelect/InsightsSelect';
import { externalLinks } from 'lib/externalLinks';
import {
  getBusinessInsights,
  ABSInsights,
  SeriesValue,
  getKeyDimentionalSeriesKey,
  Observations,
  SelectOption,
  defaultIndustry,
  getInsightsValue,
  getSelectedIndustryIndex,
} from 'services/absInsightsAPI';

import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import InsightMeasureDetails from 'components/InsightMeasureDetails/InsightMeasureDetails';
import './BusinessIndicatorsPage.css';
import { InsightsMeasures } from 'enums/InsightsMeasures';

const BusinessIndicatorsPage = () => {
  const [insightData, setInsightData] = useState<Map<string, Observations>>();
  const [industries, setIndustries] = useState<SeriesValue | null>(null);
  const [measures, setMeasures] = useState<SeriesValue | null>(null);
  const [dates, setDates] = useState<string[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<SelectOption>(defaultIndustry);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<SelectOption>({ label: '2 years', value: 8 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(false);
  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const { activeBusiness } = currentBusinesses;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  highchartsAccessibility(Highcharts);

  const timeLineOptions = [
    { label: 'Past year', value: 4 },
    { label: 'Past 2 years', value: 8 },
    { label: 'Past 3 years', value: 12 },
    { label: 'Past 4 years', value: 16 },
    { label: 'Past 5 years', value: 20 },
  ];

  useEffect(() => {
    const fetchDataForPosts = async () => {
      try {
        setLoading(true);
        setError(false);
        const data = (await getBusinessInsights()) as ABSInsights;
        // ABS Date
        const series = new Map(Object.entries(data.dataSets.find((set) => set.action === 'Information')!.series));
        setInsightData(series);
        // The measures representing the data and where to find them
        setMeasures(data.structure.dimensions.series.find((a) => a?.id === 'MEASURE')!);
        // The industries represented in the data and where to find them
        const divisions = data.structure.dimensions.series.find((a) => a?.id === 'INDUSTRY')!;
        setIndustries(divisions);
        // The dates of the data in the series
        const newDates: string[] = [];
        data.structure.dimensions.observation
          .find((a) => a?.id === 'TIME_PERIOD')!
          .values.map((time) => (time.end ? newDates.push(dayjs(time.end).format('MMM YYYY')) : false));
        setDates(newDates);
        // When the user has an active business with a valid div so it first
        if (divisions && activeBusiness?.division) {
          const matchedIndustry = divisions.values.find((div) => div.id === activeBusiness?.division);
          if (matchedIndustry?.id && matchedIndustry.name) {
            setSelectedIndustry({ label: matchedIndustry.name, value: matchedIndustry?.id });
          }
        }
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        setError(true);
        console.error(err.message);
      }
    };

    fetchDataForPosts();
  }, [activeBusiness, retry]);

  const handleChange = (e: SelectOption) => {
    setSelectedIndustry(e);
  };

  const handleTimeChange = (e: SelectOption) => {
    setSelectedTimeFrame(e);
  };

  const getIndustryOptions = () => {
    if (!industries) return [{ label: '', value: '' }];
    const values: any = [];
    industries.values.map((i) => values.push({ label: i.name, value: i.id }));
    return values.sort((a: SelectOption, b: SelectOption) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  };

  const updateCurrentToCurrentDivision = (link: string) => {
    if (selectedIndustry?.value) {
      return link.replace('..TOT.', `.${selectedIndustry.value}.TOT.`);
    }
    return link;
  };

  const displaySelectOptions = () => {
    if (loading) {
      return <div className="skeleton" style={{ height: '78px', width: '100%' }} />;
    }
    if (measures) {
      return (
        <>
          <InsightsSelect
            title="Industry"
            value={selectedIndustry}
            handleChange={handleChange}
            options={getIndustryOptions()}
            minWidth="16rem"
          />
          <InsightsSelect
            title="Time span"
            value={selectedTimeFrame}
            handleChange={handleTimeChange}
            options={timeLineOptions}
          />
        </>
      );
    }
    return false;
  };

  return (
    <DashboardLayout>
      <Flex
        direction="column"
        gap="32px"
        className="manage-connections-page-wrapper"
        testId="business-indicators-page-wrapper"
      >
        <Heading className="manage-connections-page-heading" testId="business-indicators-page-heading" level={1}>
          Industry insights
        </Heading>
        <Text color="#000000" fontWeight="400" fontSize="16px" testId="dashboard-abn-text">
          This tool allows you to access and explore{' '}
          <Link href={externalLinks.absBusinessIndicators}>ABS Business Indicators data</Link> for your chosen industry.
          The information you see is produced from what you and others report to the ABS. Your participation helps
          create this statistical picture of businesses in Australia.
        </Text>
        <Text color="#000000" fontWeight="400" fontSize="16px" testId="dashboard-abn-text">
          The following graphs show change in each indicator relative to the quarter before for the industry selected.
          This shows what businesses in the industry have experienced over time.
        </Text>
        <Flex>{displaySelectOptions()}</Flex>
        <Flex direction="column" gap="64px" justifyContent="space-around">
          {measures &&
            measures.values.map((measure, i) => {
              const InsightsMeasureConfig = InsightsMeasures.find((m) => m.key === measure.id)!;
              if (industries) {
                return (
                  <InsightMeasureDetails
                    key={measure.id}
                    tagName={InsightsMeasureConfig.tagName}
                    title={InsightsMeasureConfig.name}
                    name={InsightsMeasureConfig.title}
                    data={getInsightsValue(
                      getKeyDimentionalSeriesKey(
                        industries?.keyPosition,
                        getSelectedIndustryIndex(selectedIndustry, industries),
                        getKeyDimentionalSeriesKey(measures.keyPosition, String(i))
                      ),
                      insightData!
                    )}
                    catagories={dates}
                    color={InsightsMeasureConfig.color}
                    whatIsIt={InsightsMeasureConfig.whatIsIt}
                    whyItMatters={InsightsMeasureConfig.whyItMatters}
                    displayCount={selectedTimeFrame.value}
                    industry={selectedIndustry ? selectedIndustry.label : ''}
                    loading={loading}
                    link={updateCurrentToCurrentDivision(InsightsMeasureConfig.link)}
                    onRetry={() => setRetry(!retry)}
                  />
                );
              }
              return false;
            })}
          {!measures &&
            InsightsMeasures.map((InsightsMeasureConfig) => (
              <InsightMeasureDetails
                key={InsightsMeasureConfig.name}
                tagName={InsightsMeasureConfig.tagName}
                title={InsightsMeasureConfig.name}
                name="loading"
                data={[]}
                catagories={[]}
                color={InsightsMeasureConfig.color}
                whatIsIt={InsightsMeasureConfig.whatIsIt}
                whyItMatters={InsightsMeasureConfig.whyItMatters}
                displayCount={8}
                industry={selectedIndustry ? selectedIndustry.label : ''}
                loading={loading}
                error={error}
                onRetry={() => setRetry(!retry)}
              />
            ))}
        </Flex>
        <ValueBackCard />
        <Heading level={3} style={{ margin: '0' }}>
          Notes on the data
        </Heading>
        <Text>
          This data is in current prices. It includes incorporated and unincorporated businesses. It is not seasonally
          adjusted.
        </Text>
        <Text>
          These graphs show data from the{' '}
          <Link isExternal href={externalLinks.absBusinessIndicators}>
            Business Indicators, Australia
          </Link>{' '}
          publication. Please refer to the{' '}
          <Link isExternal href={externalLinks.absBussinessIndicatorsMethodology}>
            methodology page
          </Link>{' '}
          for more information about the data.
        </Text>
      </Flex>
    </DashboardLayout>
  );
};

export default BusinessIndicatorsPage;
