import React from 'react';
import { Button } from '@aws-amplify/ui-react';

import './SkipLink.scss';

const SkipLink = ({ skipTo }: { skipTo: string }) => {
  const handleOnClick = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const container: HTMLElement | null = document.querySelector(skipTo);

    if (container) {
      container.tabIndex = -1;
      container.focus();
      setTimeout(() => container.removeAttribute('tabindex'), 1000);
    }
  };

  return (
    <Button onClick={handleOnClick} className="skip-link" variation="link">
      Skip to main content
    </Button>
  );
};

export default SkipLink;
