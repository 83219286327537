/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useLazyQuery } from '@apollo/client';
import { AlertProps, Card, Grid, Heading, Link, Text } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { addRumSessionAttributes, recordRumCustomEventWithPageId, recordRumError } from 'services/awsRum';
import { getAllDsps } from 'utils/dspIndex';
import { useLogOut } from 'utils/useLogOut';
import { setActiveBusinessAfterClaim } from 'utils/setActiveBusinessAfterClaim';
import { GET_AUTHORISED_BUSINESSES } from 'apollo/queries/getAuthorisedBusinesses';
import { GET_DSP_ACCESS_TOKEN } from 'apollo/queries/getDSPAccessToken';
import { CLAIM_OBLIGATION } from 'apollo/queries/claimObligation';
import { ClaimObligation, GetDSPAccessToken, GetUserProfile } from 'models';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { AuthoriseDsp } from 'components/AuthoriseDsp/AuthoriseDsp';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { TextComponent } from 'components/TextComponent/Text';
import { AlertComponent } from 'components/AlertComponent/AlertComponent';
import { useUserInfo } from 'lib/userInfoHook';
import { externalLinks } from 'lib/externalLinks';
import { ApiErrorMsg } from 'enums/ApiErrorMsg';
import { checkErrorMessages } from 'utils/errorHandling';
import useDspApiErrorToast from 'hooks/useDspApiErrorToast';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { verifyCsrfToken } from 'services/authorisationTokens';
import { showNoDsp } from 'utils/getShowNoDspValue';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from 'utils/getRumAttributes';

import { DSPabsusedatapageprop, DSPwelcomepageprop } from './content';

import './AuthorisationPage.scss';

const errorBannerProps: AlertProps = {
  testId: 'authorisation-error-banner',
  isDismissible: true,
  padding: '24px 0',
  marginTop: '16px',
  maxWidth: 'unset',
  style: { gridColumn: '2 / 12' },
};

export interface AuthorisationRouterState {
  addingBusiness?: boolean;
  claimAlreadyClaimed?: boolean;
  noAuthorisedBusinesses?: boolean;
}

const AuthorisationPage = () => {
  const [searchParams] = useSearchParams();
  const authorisationCode = searchParams.get('code');
  const stateParamsRaw = searchParams.get('state');
  const stateParams = stateParamsRaw ? JSON.parse(decodeURIComponent(stateParamsRaw)) : {};
  const authorisationDsp = stateParams.dsp ?? null;
  const csrfToken = stateParams.token ?? null;
  const accessDenied = searchParams.get('error') === 'access_denied';

  const userInfo = useUserInfo();
  const navigate = useNavigate();

  const routerState = useLocation().state as AuthorisationRouterState;
  const addingBusiness = routerState?.addingBusiness ?? false;
  const claimAlreadyClaimed = routerState?.claimAlreadyClaimed ?? false;

  const hasAuthorisedBusinesses = authorisedBusinesses().authorisedBusinesses.length > 0;
  const { oktaAuth } = useOktaAuth();
  const [claimedError, setClaimedError] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const [toastToShow, showToastOpenIfDSPError, clearDspApiToast] = useDspApiErrorToast();
  const cardSelectedState = useState(false);

  // Get DSP Access Token call - after redirect from DSP authentication
  const [getDspAccessToken, getTokenResponse] = useLazyQuery<GetDSPAccessToken | undefined>(GET_DSP_ACCESS_TOKEN, {
    onCompleted: (response) => {
      clearDspApiToast();
      const errorMessages = response?.getDSPAccessToken.errorMessages ?? [];

      if (response?.getDSPAccessToken.success) {
        // If no new orgs
        if (response.getDSPAccessToken.newOrganisations.length === 0) {
          navigate('/dashboard');
          // If there are some new organisations
        } else if (authorisationDsp === 'MYOB') {
          navigate('/select-business', {
            state: {
              newOrganisations: response.getDSPAccessToken.newOrganisations,
              dsp: authorisationDsp,
            },
          });
        } else {
          // Call claim obligation for Xero using first org returned in array
          claimObligation({
            variables: {
              organisationId: response.getDSPAccessToken.newOrganisations[0].organisationId,
              role: 'role',
              dsp: authorisationDsp?.toUpperCase(),
            },
          });
        }
      } else {
        showToastOpenIfDSPError(errorMessages);
      }
    },
    onError: (error) => recordRumError(error),
  });

  // Get User Profile call - check if there are any authorised businesses for the user
  const [getAuthorisation, getAuthoriseResponse] = useLazyQuery<GetUserProfile>(GET_AUTHORISED_BUSINESSES, {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (response?.getUserProfile.user?.accessDetails && response.getUserProfile.user.accessDetails.length > 0)
        navigate('/dashboard');
    },
    onError: (error) => recordRumError(error),
  });

  // Claim Obligation api call
  const [claimObligation, claimObligationResponse] = useLazyQuery<ClaimObligation | undefined>(CLAIM_OBLIGATION, {
    onCompleted: (response) => {
      const errorMessages = response?.claimObligation.errorMessages ?? [];
      if (response?.claimObligation.success) {
        setActiveBusinessAfterClaim(claimObligationResponse.variables?.organisationId);
        navigate('/dashboard');
        // If the org we tried to claim does not have abn in the DB then nav to abn verification
      } else if (
        response &&
        (checkErrorMessages(errorMessages, ApiErrorMsg.noAbnFoundForClaim) ||
          checkErrorMessages(errorMessages, ApiErrorMsg.dspApiNoAbnError))
      ) {
        navigate('/claim-obligation', {
          state: {
            organisationId: claimObligationResponse.variables?.organisationId,
            businessName: response.claimObligation.dspBusinessName,
            businessAbn: response.claimObligation?.dspAbn ?? '',
            role: claimObligationResponse.variables?.role,
            dsp: claimObligationResponse.variables?.dsp,
          },
        });
      } else if (response && checkErrorMessages(errorMessages, ApiErrorMsg.alreadyClaimed)) {
        setClaimedError(true);
      }
    },
    onError: (error) => recordRumError(error),
  });

  // Check if there is an authorisation code, dsp, and token in the query params
  useEffect(() => {
    if (authorisationCode && authorisationDsp && csrfToken) {
      // Verifiy the token against secret kept in local storage
      verifyCsrfToken(csrfToken)
        .then((verified) => {
          if (verified) {
            // Continue flow on successful verification
            getDspAccessToken({ variables: { dsp: authorisationDsp.toUpperCase(), authCode: authorisationCode } });
          } else {
            // stop auth flow and show error banner
            setTokenError(true);
          }
        })
        .catch(() => setTokenError(true));
    } else if (!addingBusiness && !accessDenied) {
      getAuthorisation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInfo?.sub) {
      addRumSessionAttributes({ oktaUserId: userInfo.sub });
    }
  }, [userInfo]);

  if (getTokenResponse.loading || claimObligationResponse.loading || getAuthoriseResponse.loading) {
    return <LoadingSpinner fullPage />;
  }

  const HandleLogout = () => {
    useLogOut(oktaAuth);
  };

  const allDsps = showNoDsp ? getAllDsps() : getAllDsps().filter((dsp) => dsp.id !== 'NODSP');

  // outer wrapper div allows matching alignment of header without triggering horizontal scrolling
  return (
    <Grid
      testId="authorisation-form-container"
      justifyContent="flex-start"
      className="menu-wrapper1"
      templateColumns="repeat(12, 1fr)"
      gap="16px"
      rowGap="0"
      marginBottom="30px"
    >
      {(claimedError || claimAlreadyClaimed) && (
        <AlertComponent
          title="Report already claimed"
          textHeaderComponent={
            <>
              The report for this business has already been claimed by another organisation. Please{` `}
              <Link
                href={externalLinks.absContactUs}
                isExternal
                onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
              >
                contact us
              </Link>{' '}
              for assistance.
            </>
          }
          variation="error"
          alertProps={{
            ...errorBannerProps,
            onDismiss: () => setClaimedError(false),
          }}
        />
      )}
      {tokenError && (
        <AlertComponent
          title="Error authorising business"
          textHeaderComponent={
            <>
              An error was encountered when attempting to authorise that business. Please try again or {` `}
              <Link
                href={externalLinks.absContactUs}
                isExternal
                onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
              >
                contact us
              </Link>{' '}
              for assistance.
            </>
          }
          variation="error"
          alertProps={{
            ...errorBannerProps,
            onDismiss: () => setTokenError(false),
          }}
        />
      )}
      {routerState?.noAuthorisedBusinesses && (
        <AlertComponent
          title="Your accounting software is no longer connected to ABS Business Reporting"
          textHeaderComponent={
            <>
              This may be because someone has removed the connection to ABS Business Reporting from within your
              accounting software, or because your access levels in your accounting software have changed.
              <br />
              <br />
              Please connect a business using an option below to continue.
            </>
          }
          variation="warning"
          alertProps={{
            ...errorBannerProps,
            onDismiss: () => (routerState.noAuthorisedBusinesses = false),
          }}
        />
      )}
      {toastToShow}
      {/* Note: left margin mimics grid alignment of header */}
      <Grid
        templateColumns="1fr"
        templateRows={claimedError ? '104px auto auto' : '148px auto auto'}
        style={{ gridColumn: '2 / 12' }}
      >
        <Card
          paddingTop={claimedError ? '8px' : '52px'}
          columnStart="1"
          columnEnd="-1"
          testId="authorisation-page-breadcrumb-card"
        >
          {hasAuthorisedBusinesses ? (
            <Breadcrumb text="Back to manage connections" onClick={() => navigate('/manage-connections')} />
          ) : (
            <Breadcrumb text="Log out" onClick={HandleLogout} />
          )}
        </Card>
        <Card testId="authorisation-page-top-content-card">
          <Heading
            testId="text-component-card-heading-welcome"
            color="#326297"
            margin="0 0 36px"
            level={1}
            fontWeight="600"
            lineHeight="56px"
            letterSpacing="-0.04em"
          >
            Welcome, {userInfo?.name}
          </Heading>
          {DSPwelcomepageprop.list.map((paragraph, index) => (
            <Text
              testId="text-component-card-text-welcome"
              fontSize="18px"
              maxWidth="812px"
              lineHeight="20.6px"
              paddingBottom="7px"
              key={`textContent-${index}`}
            >
              {paragraph}
            </Text>
          ))}
          <Heading
            testId="authorisation-form-heading"
            fontSize="23px"
            lineHeight="28px"
            letterSpacing="-0.01em"
            fontWeight="500"
            padding="32px 0 16px 0"
            level={2}
          >
            How would you like to continue?
          </Heading>
          <Grid className="dsp-list" templateColumns="repeat(2, 492px)" autoRows="120px" gap="32px" marginBottom="60px">
            {allDsps.map((dspInfo) => (
              <AuthoriseDsp dspId={dspInfo.id} key={dspInfo.id} cardSelectedState={cardSelectedState} />
            ))}
          </Grid>
        </Card>
        <Card testId="authorisation-page-bottom-content-card">
          <TextComponent
            className="tiledList"
            style={DSPabsusedatapageprop.style}
            heading={DSPabsusedatapageprop.heading}
            list={DSPabsusedatapageprop.list}
            footer={DSPabsusedatapageprop.footer}
            uniqueId={DSPabsusedatapageprop.uniqueId}
          />
          <Text testId="authorisation-form-bottom-text" textAlign="center" fontSize="16px" lineHeight="22px">
            Need help?{' '}
            <Link
              testId="authorisation-form-contact-us-link"
              isExternal
              href={DSPabsusedatapageprop.linkContact}
              onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
            >
              Contact us!
            </Link>
          </Text>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AuthorisationPage;
